import { SubjectItem } from '../../SystemTenant/AROKMS/SubjectTypes';
import { KTreeNode } from './KTreeDataTypes';

export interface DisplayTableResponse {
  data: DynamicDataItem[];
  dataTotal?: {
    [key: string]: number;
  };
  columns: Column[];
  columnInitialValues: DynamicDataItem;
  layoutView: string;
  rowCount: number;
  totalPage: number;
  currentPage: number;
  totalDependentCube: number;
  cubeSyncStatus: string | null;
  cellColor: {
    [key: string]: string;
  };
}

export interface DynamicDataItem {
  data: {
    [key: string]: string | number | boolean;
  };
  tableDefinitionId: {
    [key: string]: string | number | boolean;
  };
}

export interface DisplayTableDeletePayload {
  subjectId: string | number;
  ids: string[];
}

export interface Column {
  field: string;
  headerName: string;
  tableDefinitionId: string;
  colSubjectId: string;
  type: any;
  width: any;
  editable: any;
  sortable: any;
  filter: any;
  resizable: any;
  hide: any;
  readOnlyColumn: boolean;
  pinned: any;
  dataTypeCode: string;
  renderType: any;
  allDropdownOptions: any;
  dropdownOptions: any;
  ruleTypeCode: string;
  renderAsRuleTypeCode: string;
  renderAsRuleTypeParams: string;
  ktreeOptions: any;
  ruleTypeParams: string;
  nullable: boolean;
  numeric: boolean;
  allowEmpty: boolean;
}

// Display Dynamic Form

export interface DisplayDynamicFormResponse {
  initialValues: { [key: string]: any };
  fields: Field[];
  primaryKeyField: Field;
  subject: SubjectItem;
  customRule: { [key: string]: CustomRuleObject };
}

export interface CustomRuleObject {
  action: string;
  conditions: CustomRuleCondition[];
  parameterValue: string | null;
}

export interface CustomRuleCondition {
  id: string;
  field: string;
  operator: string;
  operatorType: string;
  operatorValue: string;
  value: string;
}

export interface Field {
  id: string;
  name: string;
  label: string;
  realLabel: string;
  type: string;
  placeholder: any;
  realPlaceholder: string;
  options?: Option[];
  ktreeOptions: KTreeNode[] | KTreeNode | null;
  allowEmpty: boolean;
  procedureReadOnly: boolean;
  columnEditable: boolean;
  disabled: boolean;
  colSubjectId: string | null;
  value: string;
  ruleTypeCode: string;
  ruleTypeParams: string;
  tableDefinitionId: string;
  validationType: string;
  customRegex: string | null;
  customRegexErrorMessage: string | null;
  componentType: string | null;
  validations: FormikFormValidation[];
  description: string | null;
}

export interface FormikFormValidation {
  type: string;
  params: [string];
}

export interface Option {
  label: string;
  value: string;
  isNew?: boolean;
  parent?: string;
}

export interface DataInputFileUploadResponse {
  fileName: string;
  fileURL: string;
  objectId: string;
}

export interface GetPullAttributeMappingResponse {
  [key: string]: {
    [key: string]: any;
  };
}

export interface ResponseNextPrimaryKeySuggestion {
  nextPrimaryKey: string;
}

export interface RequestNextPrimaryKeySuggestion {
  subjectId: string;
  comparatorId: string;
}

export interface DataInputUploadFileImportRequest {
  subjectId: string;
  fileType: string;
  formData: FormData;
  withSyncCube?: boolean;
  operationType?: string;
}

export enum DataInputImportActivityStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  ON_PROGRESS = 'ON_PROGRESS',
  COMPLETED = 'COMPLETED',
  READING_DATA = 'READING_DATA',
  VALIDATING_DATA = 'VALIDATING_DATA',
  CONNECTING = 'CONNECTING',
}

export enum EventSyncCubeActivityStatus {
  WAITING = 'WAITING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  REQUIRED = 'REQUIRED',
}

export interface DataInputImportActivity {
  status: DataInputImportActivityStatus;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  channelName: string | null;
  importType: 'SUBJECT' | 'EVENT';
  totalRecords: number;
  totalInsertedRecords: number;
  totalFailedRecords: number;
  totalCurrentProcessedRecords: number;
  totalCurrentReadingRecords: number;
  totalCurrentValidatingRecords: number;
  id: string;
  failedRecordsFileUrl: string;
  systemFailedMessage: string;
}

export interface EventSyncCubeActivity {
  status: EventSyncCubeActivityStatus;
  message: string;
}

export interface DataInputObjectFile {
  id: string;
  objectName: string;
  description: string;
  recordId: string;
  objectType: string;
  link: string;
}

export interface DataInputPanelOptions {
  options: KTreeNode[];
  ktreeName: string;
  ktreeId: string;
  attributeColumnDisplayName: string;
  attributeColumnName: string;
  attributeId: string;
  tableDefinitionId: string;
}
