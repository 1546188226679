import qs from 'query-string';
import _ from 'lodash';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  GridRowModesModel,
  useGridApiRef,
  GridRowModes,
  GridRowParams,
  GridSortDirection,
  GridRowId,
  GridSelectionModel,
  GridSortModel,
  getGridStringOperators,
  GridFilterModel,
  GridColumns,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import {
  useEditSubject,
  useGetSubjectDetails,
  useSubjectDropdown,
} from 'services/v1/SystemTenant/AROKMS/SubjectService';
import {
  useTableDefinitionList,
  useUpsertTableDefinition,
  useDeleteTableDefinitionItem,
} from 'services/v1/SystemTenant/AROKMS/TableDefinitionService';
import { useDownloadDataImportTemplate, useExportDataTable } from 'services/v1/Misc/UploadService';
import { ModalImportComponent, ModalImportState } from 'components/ModalComponent/ModalImportComponent';
import { PaginationAndSortingParams } from 'types/api/Common/PaginationTypes';
import { DropdownItem, TableDefinitionItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { CustomDataTable } from 'components/DatatableComponent';
import { MainLayoutComponent } from 'components/LayoutComponent/SidebarLayout/MainLayoutComponent';
import { EVENT_TABLE_DEFINITION_LOCK_STATUS, SettingPanel } from './components/TabelDefinitionListPanel';
import { RenderButtonAction } from './components/ButtonAction';
import { renderSelectEditInputCell } from './components/SelectEditInputCell';
import { ModalParameter, ModalParamState } from './components/ModalParameter';
import { AxiosDefaultErrorEntity } from 'types';
import { getErrorMessage } from 'utils/Error';
import { getFileName, downloadFileFromURL } from 'utils/DownloadFIle';
import { DASHBOARD_VIEW_MODE } from 'constant/DashboardTypeConstant';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import { ModalCustomColumnOrder } from './components/ModalColumnConfig/ModalCustomColumnOrder';
import { ModalTableDefinitionDescription } from './components/ModalTableDefinitionDescription';
import { TABLE_DEFINITION_OPTIONS_MENU } from './components/TableDefinitionOptionsMenu';
import { ModalFieldValidationOptions } from './ModalValidationOptions';
import { RenderButtonActionValidation } from './components/ButtonActionValidationOption';
import { ModalConfigPrimaryKeySuggestion } from './components/ModalConfigPrimaryKeySuggestion';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

interface MemberListFilter extends PaginationAndSortingParams {
  subjectId?: string | number;
}
interface OptionItem {
  value: string | number;
  label: string;
}

export enum TABLE_DEFINITION_TYPE {
  TABLE_DEFINITION = 'TABLE_DEFINITION',
  EVENT_DEFINITION = 'EVENT_DEFINITION',
}

const PAGE_TITLE = {
  [TABLE_DEFINITION_TYPE.TABLE_DEFINITION]: 'Table Definition',
  [TABLE_DEFINITION_TYPE.EVENT_DEFINITION]: 'Event Definition',
};

const TABLE_DEFINITION_SUBJECT_TYPE = {
  [TABLE_DEFINITION_TYPE.TABLE_DEFINITION]: [SUBJECT_TYPE.TABLE, SUBJECT_TYPE.VIEW],
  [TABLE_DEFINITION_TYPE.EVENT_DEFINITION]: SUBJECT_TYPE.EVENT,
};

const FILE_NAME = {
  EXPORT: 'EXPORT_TABLE_DEFINITIONS',
  IMPORT: 'IMPORT_TABLE_DEFINITIONS',
  TEMPLATE: 'IMPORT_TEMPLATE_TABLE_DEFINITIONS',
};

const overrideTableStyle = {
  '& .MuiDataGrid-cell--editing': {
    maxHeight: '40px !important',
    minHeight: '40px !important',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
    border: 'none',
  },
};

const panelStyles = {
  transform: 'translate3d(704px, 140.667px, 0px) !important',
  '& .MuiDataGrid-panelFooter': {
    display: 'none',
  },
};
const DEFAULT_TABLE_SORT_MODEL: { field: string; sort: GridSortDirection }[] = [
  {
    field: 'attribute',
    sort: 'asc',
  },
];

const rowInitialObjectAssociatedAttribute = {
  allowEmpty: '',
  attribute: {
    label: '',
    value: '',
  },
  ruleType: {
    label: '',
    value: '',
  },
  dataType: {
    label: '',
    value: '',
  },
  ktreeDefinition: {
    label: '',
    value: '',
  },
  isNew: true,
};
const rowInitialObjectAssociatedSubject = {
  associatedSubject: {
    label: '',
    value: '',
  },
  ruleType: {
    label: '',
    value: '',
  },
  ktreeDefinition: {
    label: '',
    value: '',
  },
  dataType: {
    id: 6,
    label: 'Associated Subject Rule',
    value: 6,
  },
  displayName: '',
  isNew: true,
};

const DATA_TABLE_TOOLBAR_CONFIG = {
  deleteButtonText: 'Delete',
  fieldFocusOnInsert: 'attribute',
  insertInitialObject: rowInitialObjectAssociatedSubject,
};

export default function TableDefinitionListPage({ type }: { type: TABLE_DEFINITION_TYPE }) {
  const { dashboardViewMode } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { sid = null } = qs.parse(location.search);

  const [modalImportState, setModalImportState] = useState<ModalImportState>({
    open: false,
  } as ModalImportState);
  const [modalOrderColumn, setModalOrderColumn] = useState<boolean>(false);
  const [modalDescription, setModalDescription] = useState<boolean>(false);
  const [filter, setFilter] = useState<MemberListFilter>({
    page: 0,
    size: 20,
    filterValue: '',
    sortType: '',
    filterOperator: '',
  });
  const [selectedDataID, setSelectedDataID] = useState<GridRowId[]>([]);
  const [muiDataTableKey, setMuiDataTableKey] = useState<number>(0);
  const [rowData, setRowData] = useState<TableDefinitionItem[]>([]);
  const [modalParameterState, setModalParameterState] = useState<ModalParamState>({
    message: '',
    open: false,
    tableDefinitionId: null,
  });

  const [modalValidationOptions, setModalValidationOptions] = useState<ModalParamState>({
    message: '',
    open: false,
    tableDefinitionId: null,
  });
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({ open: false, message: '' });
  const [modalSugesstionPrimaryKey, setModalSugesstionPrimaryKey] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [filterButtonElement, setFilterButtonElement] = useState<HTMLButtonElement | null>(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState<{ label: string; value: string | number }>({
    label: '',
    value: '',
  });
  const [initialOption, setInitialOption] = useState<OptionItem | null>(null);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
  const [selectedTabPanel, setSelectedTabPanel] = useState<string>('ASSOCIATED_SUBJECT');
  const [selectedLockStatus, setSelectedLockStatus] = useState<DropdownItem>(
    EVENT_TABLE_DEFINITION_LOCK_STATUS[0] as DropdownItem
  );

  // Service call
  const { mutate: updateSubject } = useEditSubject();
  const { data: subjectDetails, isLoading: isLoadingSubjectDetails } = useGetSubjectDetails(selectedSubjectId?.value);
  const { mutate: upsertTableDefinition } = useUpsertTableDefinition(
    {
      ...filter,
      subjectId: selectedSubjectId?.value,
    },
    selectedTabPanel
  );
  const { mutateAsync: deleteTableDefinitionById } = useDeleteTableDefinitionItem();
  const {
    data: tableDefinitionList,
    isLoading,
    refetch: refetchTableDefinitionList,
  } = useTableDefinitionList(
    {
      ...filter,
      subjectId: selectedSubjectId?.value,
    },
    selectedTabPanel
  );

  const isAnyEdit = useMemo(() => {
    return rowModesModel && Object.values(rowModesModel).some((item) => item.mode === GridRowModes.Edit);
  }, [rowModesModel]);
  const { data: subjectDropdownData } = useSubjectDropdown(undefined, TABLE_DEFINITION_SUBJECT_TYPE[type]);
  const { mutate: exportDataTable } = useExportDataTable();
  const { mutate: downloadDataImportTemplate } = useDownloadDataImportTemplate();

  const subjectDropdownOptions = useMemo(() => {
    if (subjectDropdownData?.data) {
      if (sid) {
        const initialSubject = subjectDropdownData.data.find((item) => item.id?.toString() === sid);
        if (initialSubject) {
          let initalOption = {
            label: initialSubject.subject,
            value: initialSubject.id,
            // @ts-ignore
            type: initialSubject.type,
          };
          setInitialOption(initalOption);
          setSelectedSubjectId(initalOption);
        } else {
          setSelectedSubjectId({
            label: subjectDropdownData?.data[0]?.subject || '',
            value: subjectDropdownData?.data[0]?.id || '',
            // @ts-ignore
            type: subjectDropdownData?.data[0]?.type || '',
          });
        }
      } else {
        setSelectedSubjectId({
          label: subjectDropdownData?.data[0]?.subject || '',
          value: subjectDropdownData?.data[0]?.id || '',
          // @ts-ignore
          type: subjectDropdownData?.data[0]?.type || '',
        });
      }
      return subjectDropdownData.data.map((item) => ({
        value: item.id + '',
        label: item.subject,
        type: item.type,
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectDropdownData]);

  // @ts-ignore
  const isSubjectView = selectedSubjectId?.type === SUBJECT_TYPE.VIEW;

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const isBuilderViewOnly = dashboardViewMode === DASHBOARD_VIEW_MODE.VIEW_ONLY;

  const columnsAttribute = useMemo<GridColumns>(() => {
    const attributeCols: GridColumns = [
      {
        field: 'attribute',
        headerName: `${t('PAGE.TABLE_DEFINITION.FIELD_ATTRIBUTE')} ${selectedSubjectId.label}`,
        width: 190,
        editable: true,
        filterable: true,
        sortable: true,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.attribute;
        },
        renderEditCell: (params) =>
          // @ts-ignore
          renderSelectEditInputCell({ ...params, selectedSubjectId: selectedSubjectId.value }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'dataType',
        headerName: t('PAGE.TABLE_DEFINITION.DATA_TYPE_ATTRIBUTE'),
        width: 180,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.dataType;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
            // @ts-ignore
            selectedSubjectType: selectedSubjectId.type,
          }),
      },
      {
        field: 'ruleType',
        headerName: t('PAGE.TABLE_DEFINITION.RULE_TYPE'),
        width: 380,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.ruleType;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
            // @ts-ignore
            selectedSubjectType: selectedSubjectId.type,
          }),
      },
      {
        field: 'ktreeDefinition',
        headerName: 'KTree Filter',
        width: 380,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.ktreeDefinition;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
          }),
      },
      {
        field: 'params',
        headerName: t('PAGE.TABLE_DEFINITION.PARAMETER'),
        width: 170,
        editable: false,
        valueGetter: (params) => params.row,
        renderCell: (props) => (
          <RenderButtonAction
            {...props}
            setModalParameterState={setModalParameterState}
            setRowModesModel={setRowModesModel}
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: 'validationOptions',
        headerName: 'Validation Options',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row,
        renderCell: (props) => (
          <RenderButtonActionValidation
            {...props}
            setModalParameterState={setModalValidationOptions}
            setRowModesModel={setRowModesModel}
          />
        ),
        sortable: false,
        filterable: false,
      },
      { field: 'allowEmpty', headerName: 'Allow Empty', type: 'boolean', width: 120, editable: true },
    ];
    if (isBuilderViewOnly) {
      return attributeCols;
    }
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (row.locked) return [];

          if (isInEditMode) {
            // @ts-ignore
            return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
          }

          return [
            // @ts-ignore
            <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
          ];
        },
      },
      ...attributeCols,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjectId, setRowModesModel, setModalParameterState, t, rowModesModel, isAnyEdit, isBuilderViewOnly]);
  const columnsSubject = useMemo<GridColumns>(() => {
    const subjectCols: GridColumns = [
      {
        field: 'associatedSubject',
        headerName: t('SIDEBAR_MENU.SUBJECT'),
        width: 190,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params.row?.associatedSubject;
        },

        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
            // @ts-ignore
            parentSubjectId: selectedSubjectId.value,
            selectedSubjectId: selectedSubjectId.value,
            // @ts-ignore
            selectedSubjectType: selectedSubjectId.type,
          }),
        filterOperators: getGridStringOperators().filter((operator) => ['contains', 'equals'].includes(operator.value)),
      },
      {
        field: 'displayName',
        headerName: t('PAGE.TABLE_DEFINITION.DISPLAY_NAME'),
        width: 180,
        editable: true,
        filterable: true,
        sortable: true,
        preProcessEditCellProps: (params) => {
          return {
            ...params,
            value: params?.otherFieldsProps?.associatedSubject.value?.label,
          };
        },
      },
      {
        field: 'ruleType',
        headerName: t('PAGE.TABLE_DEFINITION.RULE_TYPE'),
        width: 380,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.ruleType;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
            // @ts-ignore
            selectedSubjectType: selectedSubjectId.type,
          }),
      },
      {
        field: 'ktreeDefinition',
        headerName: 'KTree Filter',
        width: 380,
        editable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return <span>{params?.value?.label}</span>;
        },
        valueGetter: (params) => {
          return params?.row?.ktreeDefinition;
        },
        renderEditCell: (params) =>
          renderSelectEditInputCell({
            ...params,
          }),
      },
      {
        field: 'params',
        headerName: t('PAGE.TABLE_DEFINITION.PARAMETER'),
        width: 170,
        editable: false,
        valueGetter: (params) => params.row,
        renderCell: (props) => (
          <RenderButtonAction
            {...props}
            setModalParameterState={setModalParameterState}
            setRowModesModel={setRowModesModel}
          />
        ),
        sortable: false,
        filterable: false,
      },
      { field: 'allowEmpty', headerName: 'Allow Empty', type: 'boolean', width: 120, editable: true },
    ];

    if (isBuilderViewOnly) {
      return subjectCols;
    }
    return [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Edit',
        width: 30,
        cellClassName: 'actions',

        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            // @ts-ignore
            return [<GridActionsCellItem icon={<SaveIcon />} label='Save' onClick={handleSaveClick(id)} />];
          }

          return [
            // @ts-ignore
            <GridActionsCellItem disabled={isAnyEdit} icon={<EditIcon />} label='Edit' onClick={handleEditClick(id)} />,
          ];
        },
      },
      ...subjectCols,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjectId, setRowModesModel, setModalParameterState, t, rowModesModel, isAnyEdit, isBuilderViewOnly]);

  const DataMutationSuccessHandlerOption = useMemo(
    () => ({
      onError: (error: AxiosDefaultErrorEntity) => {
        setSnackbar({ children: getErrorMessage(error), severity: 'error' });
      },
    }),
    []
  );

  useEffect(() => {
    if (tableDefinitionList?.data?.data) {
      setRowData(tableDefinitionList?.data?.data);
    }
  }, [tableDefinitionList]);

  useEffect(() => {
    if (subjectDetails?.data) {
      if (subjectDetails.data.tableDefinitionLocked) {
        setSelectedLockStatus(EVENT_TABLE_DEFINITION_LOCK_STATUS[1] as DropdownItem);
      } else {
        setSelectedLockStatus(EVENT_TABLE_DEFINITION_LOCK_STATUS[0] as DropdownItem);
      }
    }
  }, [subjectDetails]);

  const isStaticSubject = useMemo(() => {
    if (subjectDetails) return subjectDetails?.data?.static;
    return false;
  }, [subjectDetails]);

  const handleOnRowUpdate = useCallback(
    (newData: TableDefinitionItem, oldData: TableDefinitionItem | undefined) => {
      if (selectedTabPanel === 'ASSOCIATED_ATTRIBUTE') {
        if (!newData.attribute.value && !newData.dataType.value && !newData.ruleType.value) {
          // handleCancelInsert(newData.id);
        } else if (!newData.attribute.value || !newData.dataType.value || !newData.ruleType.value) {
          return;
        } else {
          if (newData.isNew) {
            upsertTableDefinition(
              {
                attributeId: newData.attribute.value,
                ruleTypeId: newData.ruleType.value,
                subjectId: selectedSubjectId.value,
                allowEmpty: newData.allowEmpty,
                ktreeDefinitionId: newData?.ktreeDefinition?.value,
              },
              DataMutationSuccessHandlerOption
            );
            return { ...newData };
          }
          if (!_.isEqual(newData, oldData)) {
            upsertTableDefinition(
              {
                attributeId: newData.attribute.value,
                ruleTypeId: newData.ruleType.value,
                ruleTypeParams: newData.ruleTypeParams,
                subjectId: selectedSubjectId.value,
                ruleTypeParamsId: newData.ruleTypeParamsId,
                ktreeDefinitionId: newData?.ktreeDefinition?.value,
                allowEmpty: newData.allowEmpty,
                id: newData.id,
              },
              DataMutationSuccessHandlerOption
            );
          }
          return newData;
        }
      } else {
        if (!newData.associatedSubject.value && !newData.ruleType.value) {
          // handleCancelInsert(newData.id);
        } else if (!newData.associatedSubject.value || !newData.ruleType.value) {
          return;
        } else {
          if (newData.isNew) {
            upsertTableDefinition(
              {
                associatedSubjectId: newData.associatedSubject.value,
                displayName: newData.displayName.trim(),
                ruleTypeId: newData.ruleType.value,
                subjectId: selectedSubjectId.value,
                allowEmpty: newData.allowEmpty,
                ktreeDefinitionId: newData?.ktreeDefinition?.value,
              },
              DataMutationSuccessHandlerOption
            );
            return { ...newData };
          }

          if (!_.isEqual(newData, oldData)) {
            upsertTableDefinition(
              {
                associatedSubjectId: newData.associatedSubject.value,
                displayName: newData.displayName.trim(),
                ruleTypeId: newData.ruleType.value,
                subjectId: selectedSubjectId.value,
                allowEmpty: newData.allowEmpty,
                ruleTypeParams: newData.ruleTypeParams,
                ktreeDefinitionId: newData?.ktreeDefinition?.value,
                id: newData.id,
              },
              DataMutationSuccessHandlerOption
            );
          }
          return newData;
        }
      }
    },
    [upsertTableDefinition, selectedSubjectId, selectedTabPanel, DataMutationSuccessHandlerOption]
  );

  const handleOnRowDoubleClick = useCallback(
    (params: GridRowParams, event: React.MouseEvent) => {
      if (isBuilderViewOnly) return;
      const { id, row } = params;

      if (row.locked) return;
      setRowModesModel((oldModel: GridRowModesModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit },
      }));
    },
    [setRowModesModel, isBuilderViewOnly]
  );

  const handleOnLockStatusChange = (data: OptionItem) => {
    if (subjectDetails?.data) {
      updateSubject({
        isTableDefinitionLocked: data.value === EVENT_TABLE_DEFINITION_LOCK_STATUS[1].value,
        id: selectedSubjectId.value,
      });
    }
    setSelectedLockStatus(data as DropdownItem);
  };

  const handleOnSubjectChange = (data: OptionItem) => {
    setSelectedSubjectId(data);

    setMuiDataTableKey((prevKey) => prevKey + 1);
    setFilter((prevFilter) => ({ ...prevFilter, sortType: 'asc', filterValue: '' }));
    if (selectedDataID.length) {
      setSelectedDataID([]);
    }
    // @ts-ignore
    if (data?.type === SUBJECT_TYPE.VIEW) {
      setSelectedTabPanel('ASSOCIATED_ATTRIBUTE');
    }

    navigate({
      search: `?sid=${data.value}`,
    });
  };

  const handleOnClickDeleteButton = () => {
    const selectedData = rowData.filter((item) => selectedDataID.includes(item.id));
    setModalDeleteState({
      ...modalDeleteState,
      open: true,
      message: `Table Definition : ${selectedData
        .map((item) => {
          if (selectedTabPanel === 'ASSOCIATED_ATTRIBUTE') {
            return item.attribute.label;
          }
          return item.associatedSubject.label;
        })
        .join(', ')}`,
    });
  };

  const handleOnDeleteData = async () => {
    try {
      if (selectedDataID.length) {
        for (const id of selectedDataID) {
          await deleteTableDefinitionById({ id });
        }
        setModalDeleteState({
          ...modalDeleteState,
          open: false,
          message: '',
        });
      }
    } catch (error) {
      setSnackbar({ children: 'Something went wrong when deleting data!', severity: 'error' });
    }
  };

  const handleOnSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setFilter({ ...filter, sortType: 'asc' });
      return;
    }
    setFilter({ ...filter, sortType: model[0].sort });
  };
  const handleOnFilterModelChange = (params: GridFilterModel) => {
    let filterObj = {};
    if (params.items.length) {
      filterObj = {
        filterValue: params.items[0].value,
        filterOperator: params.items[0].operatorValue,
      };
    } else {
      filterObj = {
        filterValue: '',
        filterOperator: 'contains',
      };
    }
    setFilter({
      ...filter,
      ...filterObj,
    });
  };

  const handleOnTabPanelChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTabPanel(newValue);
    setFilter((prevFilter) => ({ ...prevFilter, sortType: 'asc', filterValue: '' }));
    setMuiDataTableKey((prevKey) => prevKey + 1);
    if (selectedDataID.length) {
      setSelectedDataID([]);
    }
  };

  const handleOnDownloadTemplateClick = (selectedFileType: string) => {
    downloadDataImportTemplate(
      {
        fileType: selectedFileType,
        category: 'table-definition',
        parameters: {
          category: selectedTabPanel,
        },
      },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(`${FILE_NAME.TEMPLATE}_${selectedTabPanel}`, selectedFileType));
        },
      }
    );
  };

  const handleChangePage = (newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangePageSize = (newPageSize: number) => {
    setFilter({ ...filter, size: newPageSize });
  };

  const handleOnExportClick = (selectedFileType: string) => {
    exportDataTable(
      { category: 'table-definition', fileType: selectedFileType, dataCategory: selectedTabPanel },
      {
        onSuccess: (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          downloadFileFromURL(url, getFileName(`${FILE_NAME.EXPORT}_${selectedTabPanel}`, selectedFileType));
        },
      }
    );
  };
  const handleOnImportClick = (selectedFileType: string) => {
    setModalImportState({ ...modalImportState, fileType: selectedFileType, open: true });
  };

  const handleOnImportCompleted = () => {
    refetchTableDefinitionList();
  };
  const handleOnColOrderClick = (optionType: string) => {
    if (optionType === TABLE_DEFINITION_OPTIONS_MENU.OPTION_COLUMN_ORDER) {
      setModalOrderColumn(true);
      return;
    }
    if (optionType === TABLE_DEFINITION_OPTIONS_MENU.OPTION_SET_DESCRIPTION) {
      setModalDescription(true);
      return;
    }
    if (optionType === TABLE_DEFINITION_OPTIONS_MENU.OPTION_SET_PRIMARY_KEY_SUGGESTION) {
      setModalSugesstionPrimaryKey(true);
      return;
    }
  };

  const handleCloseSnackbar = () => setSnackbar(null);

  return (
    <MainLayoutComponent
      pageTitle={PAGE_TITLE[type]}
      breadcrumbs={[t('SIDEBAR_MENU.DASHBOARD'), t('SIDEBAR_MENU.DATA_BUILDER')]}
    >
      {isStaticSubject && (
        <Alert sx={{ mb: 2 }} severity='warning'>
          This is a static subject; table definitions cannot be edited here. Any modifications must be implemented at
          the codebase level.
        </Alert>
      )}
      <CustomDataTable
        apiRef={apiRef}
        key={muiDataTableKey}
        initialState={{
          pagination: {
            page: 0,
          },
          sorting: {
            sortModel: DEFAULT_TABLE_SORT_MODEL,
          },
        }}
        loading={isLoading || isLoadingSubjectDetails}
        rows={rowData}
        columns={selectedTabPanel === 'ASSOCIATED_SUBJECT' ? columnsSubject : columnsAttribute}
        rowCount={tableDefinitionList?.data?.rowCount}
        page={tableDefinitionList?.data?.page?.pageNumber || 0}
        pageSize={filter.size}
        getRowId={(row) => row.id}
        rowModesModel={rowModesModel}
        disableSelectionOnClick
        isRowSelectable={(row) => {
          // 13 is the id of the row that is not selectable
          // because 13 id of rule type for subect base attribute
          return !row.row.locked;
        }}
        isCellEditable={(params) => {
          return !params?.row?.locked;
        }}
        onSortModelChange={handleOnSortModelChange}
        processRowUpdate={handleOnRowUpdate}
        onRowDoubleClick={handleOnRowDoubleClick}
        onFilterModelChange={handleOnFilterModelChange}
        onProcessRowUpdateError={(error) => {
          console.log('error', error);
        }}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangePageSize}
        onSelectionModelChange={(selectedId: GridSelectionModel) => {
          setSelectedDataID(selectedId);
        }}
        rowHeight={45}
        autoHeight={rowData.length !== 0 && rowData.length > 5}
        sx={overrideTableStyle}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection={!isBuilderViewOnly}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: SettingPanel,
        }}
        componentsProps={{
          panel: {
            sx: panelStyles,
            anchorEl: filterButtonElement,
          },
          toolbar: {
            setRows: setRowData,
            isInserting: rowData.some((item) => item.isNew),
            setRowModesModel,
            setFilterButtonElement: setFilterButtonElement,
            onDeleteData: handleOnClickDeleteButton,
            onTabPanelChange: handleOnTabPanelChange,
            onDownloadTemplateClick: handleOnDownloadTemplateClick,
            onExportClick: handleOnExportClick,
            onImportClick: handleOnImportClick,
            selectedTabPanel,
            options: subjectDropdownOptions,
            onOptionChange: handleOnSubjectChange,
            onOptionMenuClick: handleOnColOrderClick,
            initialOption,
            isSubjectView,
            optionValue: selectedSubjectId,
            type,
            optionLockStatusValue: selectedLockStatus,
            onLockStatusChange: handleOnLockStatusChange,
            disabledDeleteButton: selectedDataID.length === 0,
            disabled: isLoading || rowData.some((item) => item.isNew) || isBuilderViewOnly || isStaticSubject,
            config: {
              ...DATA_TABLE_TOOLBAR_CONFIG,
              insertInitialObject:
                selectedTabPanel === 'ASSOCIATED_SUBJECT'
                  ? rowInitialObjectAssociatedSubject
                  : rowInitialObjectAssociatedAttribute,
              fieldFocusOnInsert: selectedTabPanel === 'ASSOCIATED_SUBJECT' ? 'associatedSubject' : 'dataType',
            },
          },
        }}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <ModalImportComponent
        category='table-definition'
        fileType={modalImportState.fileType}
        parameter={{ category: selectedTabPanel }}
        onImportSuccess={handleOnImportCompleted}
        visible={modalImportState.open}
        onClose={() => setModalImportState({ ...modalImportState, open: false })}
        onCancel={() => setModalImportState({ ...modalImportState, open: false })}
      />
      <ModalDeleteComponent
        visible={modalDeleteState.open}
        message={modalDeleteState.message}
        onApprove={handleOnDeleteData}
        onClose={() => setModalDeleteState({ ...modalDeleteState, open: false })}
        onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false })}
      />
      <ModalParameter
        listFilter={{
          ...filter,
          subjectId: selectedSubjectId?.value,
        }}
        selectedTab={selectedTabPanel}
        visible={modalParameterState.open}
        tableDefinitionId={modalParameterState.tableDefinitionId}
        onClose={() => setModalParameterState({ message: '', open: false, tableDefinitionId: null })}
      />
      <ModalCustomColumnOrder
        open={modalOrderColumn}
        onClose={() => {
          setModalOrderColumn(false);
        }}
        subjectId={selectedSubjectId?.value?.toString()}
      />
      <ModalTableDefinitionDescription
        open={modalDescription}
        title={`Table Definition Description - ${selectedSubjectId?.label}`}
        onClose={() => {
          setModalDescription(false);
        }}
        subjectId={selectedSubjectId?.value?.toString()}
      />

      <ModalConfigPrimaryKeySuggestion
        visible={modalSugesstionPrimaryKey}
        subjectId={selectedSubjectId?.value?.toString() || ''}
        onClose={() => {
          setModalSugesstionPrimaryKey(false);
        }}
      />

      <ModalFieldValidationOptions
        tableDefinitionId={modalValidationOptions.tableDefinitionId?.toString() || ''}
        open={modalValidationOptions.open}
        onClose={() => setModalValidationOptions({ message: '', open: false, tableDefinitionId: null })}
      />
    </MainLayoutComponent>
  );
}
