//@ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InsightsOutlined from '@mui/icons-material/InsightsOutlined';
import TreeItem from '@mui/lab/TreeItem';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import {
  useGetKtreeData,
  useGetKtreeDefinitionDetails,
  useGetKtreeLinkDashboard,
} from 'services/v1/Tenant/AROKMS/KTreeDataService';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import { ButtonAction } from './components/ButtonAction';
import { RenderDataTableError } from 'components/DatatableComponent/components/DataTableError';
import { CustomNoRowsOverlay } from 'components/DatatableComponent/components/GridOverlay';
import { RoleBasedAccessProvider } from 'components/RBAC';
import { ROLES } from 'constant/PermissonConstant';
import { SystemStatusBasedAccessProvider } from 'components/RBAC/SystemStatusBasedAccessProvider';
import { Backdrop, IconButton, Popper, MenuList, MenuItem, ListItemText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import ModalFilterKTree from './components/ModalViewKTreeData';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { getPageAnimationByRefNavigation, PAGE_REF_NAVIGATION } from 'utils/Animation';
import { useDashboardNaviagationPrefetchHandler } from 'hooks/useDashboardNavigationPrefetchHandler';
import { getCurrentMyViewIndex, getNextMyViewItem, getPreviousMyViewItem } from 'utils/MyViewUtils';
import { AnimatedPage } from 'components/AnimatedPage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface ModalDeleteProps {
  visible?: boolean;
  title?: string;
  onClose?: () => void;
  ktreeTableId?: string;
}

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

const contentContainerStyle = {
  bgcolor: '#fff',
  py: 2,
  border: '2px solid #C8D7EC',
  borderRadius: '4px',
  height: '100%',
  width: '100%',
};

export const useStylesPopper = makeStyles((theme) => {
  const color = theme;
  return {
    popper: {
      borderRadius: 6,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      zIndex: 2000,
      '&[data-popper-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
      '&[data-popper-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '100% 0',
        },
      },
      '&[data-popper-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '100% 100%',
        },
      },
      '&[data-popper-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
        '&::before': {
          transformOrigin: '0 0',
        },
      },
    },
    arrow: {
      overflow: 'hidden',
      position: 'absolute',
      width: '1em',
      height: '0.71em',
      boxSizing: 'border-box',
      color,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
      },
    },
  };
});

const loadingContainerSx = { py: 3, height: '300px', px: 2 };
export default function ViewKtreeData(props: ModalDeleteProps) {
  const { kTreeId } = useParams<{ kTreeId: string }>();
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');
  const navigationRef = searchParams.get('ref');
  const { role } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const classes = useStylesPopper();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const {
    data: ktreeData,
    isLoading: isLoadingKTree,
    isSuccess,
    isError,
    error: errorKTreeData,
  } = useGetKtreeData(kTreeId);
  const [selectedKTree, setSelectedKTree] = useState<KTreeNode | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const {
    data: ktreeDetails,
    isSuccess: isDetailsSuccess,
    error: errorKTreeDetails,
  } = useGetKtreeDefinitionDetails(kTreeId);
  const { data: breadCrumbItems } = useGetBreadcumbItems(kTreeId, NAVIGATION_COMPONENT_TYPE.K_TREE);
  const { data: ktreeLinkDashboard, isLoading: isLoadingLinkData } = useGetKtreeLinkDashboard(kTreeId);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);

  const prefetchHandler = useDashboardNaviagationPrefetchHandler();

  const [anchorEl, setAnchorEl] = useState<null | React.MouseEvent<HTMLElement>>(null);

  const isLoading = isLoadingKTree || isLoadingLinkData;

  const insightViewList = useMemo(() => {
    if (allMyViewsData?.data) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;

      if (selectedDashboard) {
        return selectedDashboard.myViews;
      }
    }

    return [];
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const currentMyViewIndex = useMemo(() => {
    if (myViewId == null) return 0;
    return getCurrentMyViewIndex(insightViewList, myViewId);
  }, [myViewId, insightViewList]);

  const nextViewItem = useMemo(() => {
    return getNextMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const previousViewItem = useMemo(() => {
    return getPreviousMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const controllerNaviations = useMemo(() => {
    const controller = [];
    if (insightViewList?.length > 1) {
      if (currentMyViewIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentMyViewIndex < insightViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }
    return controller;
  }, [currentMyViewIndex, insightViewList]);

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {};
    if (insightViewList.length > 1) {
      if (currentMyViewIndex > -1) {
        if (currentMyViewIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${insightViewList[currentMyViewIndex - 1].title}`;
        }
        if (currentMyViewIndex < insightViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${
            insightViewList[currentMyViewIndex + 1].title
          }`;
        }
      }
    }
    return defaultToolTips;
  }, [currentMyViewIndex, insightViewList]);

  const memberLinkDashboardData = useMemo(() => {
    if (ktreeLinkDashboard?.data) {
      return ktreeLinkDashboard.data.linkMemberWithDashboard;
    }
    return {};
  }, [ktreeLinkDashboard?.data]);

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data);
  }, [breadCrumbItems?.data]);

  const selectedDashboardData: QuickAccessItem[] = memberLinkDashboardData?.[selectedKTree?.value] ?? [];

  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    switch (direction) {
      case ControllerNavigationDirection.LEFT:
        if (previousViewItem) {
          const previousURL = getQuickAccessNavigateURL(previousViewItem, undefined, PAGE_REF_NAVIGATION.LEFT, role);
          previousURL && navigate(previousURL);
        }
        break;
      case ControllerNavigationDirection.RIGHT:
        if (nextViewItem) {
          const nextURL = getQuickAccessNavigateURL(nextViewItem, undefined, PAGE_REF_NAVIGATION.RIGHT, role);
          nextURL && navigate(nextURL);
        }
        break;
      default:
        break;
    }
  };

  const handleOnDashboardNavigatePrefetch = (direction: ControllerNavigationDirection) => {
    switch (direction) {
      case ControllerNavigationDirection.LEFT:
        if (previousViewItem) {
          const afterPreviousItemIndex = currentMyViewIndex - 2;
          const afterPreviousItem = insightViewList?.[afterPreviousItemIndex] || undefined;
          prefetchHandler(previousViewItem, afterPreviousItem);
        }
        break;
      case ControllerNavigationDirection.RIGHT:
        if (nextViewItem) {
          const afterNextItemIndex = currentMyViewIndex + 2;
          const afterNextItem = insightViewList?.[afterNextItemIndex] || undefined;
          prefetchHandler(nextViewItem, afterNextItem);
        }
        break;
      default:
        break;
    }
  };

  const renderTreeItem = (
    nodes: KTreeNode,
    onNodeClick: (element: React.MouseEvent<HTMLElement>, node: KTreeNode) => void
  ) => {
    const isLinkDashboardExist = memberLinkDashboardData?.[nodes.value];
    return (
      <TreeItem
        sx={{
          '& .MuiTreeItem-content': { color: '#797979' },
          '& .MuiTreeItem-group': { borderLeft: '1px dashed #C8D7EC' },
          '& .MuiTreeItem-label': {
            width: 'auto !important',
            fontSize: '14px !important',
            color: nodes.level === 0 ? '#797979' : '#000',
            fontWeight: `${nodes.level === 0 ? 400 : 700} !important`,
          },
        }}
        key={nodes?.value}
        nodeId={nodes?.value}
        label={
          <Stack direction='row' gap={1} alignItems='center'>
            <span>{nodes.label}</span>
            {isLinkDashboardExist && (
              <Tooltip title='View Dashboards' onClick={(e) => onNodeClick(e, nodes)}>
                <IconButton size='small' onClick={(e) => onNodeClick(e, nodes)}>
                  <InsightsOutlined
                    fontSize='small'
                    sx={{
                      color: '#42BB93',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
        collapseIcon={null}
      >
        {Array.isArray(nodes?.children)
          ? nodes.children.map((node: KTreeNode) => renderTreeItem(node, onNodeClick))
          : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (ktreeData?.data) {
      setExpandedIds(ktreeData.expandedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ktreeData?.data]);

  const error = errorKTreeData || errorKTreeDetails;
  const handleOnNodeClick = (e: React.MouseEvent<HTMLElement>, node: KTreeNode) => {
    setAnchorEl(e.target);
    setSelectedKTree(node);
  };

  const handleOnCloseModal = () => {
    setAnchorEl(null);
  };

  const handleOnQuickAccessClick = (item: QuickAccessItem) => {
    const navigateURL = getQuickAccessNavigateURL(item, true);
    if (navigateURL) {
      navigate(navigateURL);
    }
  };

  return (
    <TenantHomeDashboardNavigationBar error={error} breadCrumbs={breadCrumbs}>
      <Backdrop
        open={Boolean(anchorEl)}
        sx={{
          zIndex: 1100,
        }}
      />
      <AnimatedPage key={kTreeId || 'default-ktree'} variants={getPageAnimationByRefNavigation(navigationRef)}>
        <Stack justifyContent='space-between' direction='row' spacing={2} px={1} py={1.5} alignItems='center'>
          <Typography variant='subtitle1' sx={{ fontWeight: 600, pl: 1 }}>
            {ktreeDetails?.data && `Member ${ktreeDetails?.data?.ktreeName}`}
          </Typography>
          <Stack direction='row' alignItems='center'>
            {!myViewId && (
              <RoleBasedAccessProvider
                allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
              >
                <SystemStatusBasedAccessProvider>
                  {isDetailsSuccess && (
                    <ButtonAction {...ktreeDetails?.data} onClickAddFilter={() => setShowModalFilter(true)} />
                  )}
                </SystemStatusBasedAccessProvider>
              </RoleBasedAccessProvider>
            )}
            {myViewId && (
              <>
                <ControllerNavigation
                  onPrefetch={handleOnDashboardNavigatePrefetch}
                  enableNavigations={controllerNaviations}
                  tooltips={navigationControllerTooltips}
                  onNavigate={handleOnDashboardNavigate}
                />
              </>
            )}
          </Stack>
        </Stack>
        <Divider sx={{ mb: 1 }} />
        {isError && (
          <Stack sx={loadingContainerSx}>
            <RenderDataTableError title={getErrorTitle(error)} description={getErrorMessage(error)} />
          </Stack>
        )}
        {isLoading && (
          <Stack direction='column' sx={loadingContainerSx} justifyContent='center'>
            <ActivityIndicator />
          </Stack>
        )}

        {!isLoading && isSuccess && ktreeData.data && (
          <Stack alignItems='start' sx={{ py: 1, px: 2 }}>
            <Stack sx={contentContainerStyle}>
              {ktreeData?.data?.length > 0 && (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expandedIds}
                  onNodeToggle={(event, nodeIds) => {
                    event.persist();
                    let iconClicked = event.target.closest('.MuiTreeItem-iconContainer');
                    if (iconClicked) {
                      setExpandedIds(nodeIds);
                    }
                  }}
                  sx={{
                    width: '100%',
                  }}
                >
                  {ktreeData.data &&
                    isSuccess &&
                    ktreeData.data.map((node: KTreeNode) => renderTreeItem(node, handleOnNodeClick))}
                </TreeView>
              )}
              {ktreeData?.data?.length === 0 && <CustomNoRowsOverlay />}
            </Stack>
          </Stack>
        )}
      </AnimatedPage>
      <Popper
        id='asx'
        sx={{ zIndex: 1200 }}
        open={Boolean(anchorEl)}
        className={classes.popper}
        anchorEl={anchorEl}
        placement='right'
        disablePortal={false}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Stack sx={{ bgcolor: '#fff', borderRadius: 1, px: 2, py: 1 }} minWidth={400}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              {selectedKTree?.label}
            </Typography>
            <IconButton onClick={handleOnCloseModal} component='label'>
              <HighlightOffRoundedIcon sx={closeIconStyle} />
            </IconButton>
          </Stack>
          <Divider sx={{ mb: 2 }} />
          <Stack>
            <Stack direction='row' gap={1} alignItems='center'>
              <InsightsOutlined
                fontSize='small'
                sx={{
                  color: '#42BB93',
                }}
              />
              <Typography fontSize={13} component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                Dashboard
              </Typography>
            </Stack>
            <Stack>
              <MenuList dense>
                {selectedDashboardData.map((item) => (
                  <MenuItem
                    onClick={() => handleOnQuickAccessClick(item)}
                    disableRipple
                    key={item.viewId}
                    sx={{
                      pl: 0,
                      mb: 0,
                      mr: 0,
                      '&:hover': {
                        color: '#42BB93 !important',
                        '& .MuiListItemIcon-root': {
                          color: '#42BB93 !important',
                        },
                      },
                    }}
                  >
                    <ListItemText>
                      <Typography variant='input-label-gray'>{item.title}</Typography>
                    </ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </Stack>
          </Stack>
        </Stack>
      </Popper>

      <ModalFilterKTree visible={showModalFilter} onClose={() => setShowModalFilter(false)} />
    </TenantHomeDashboardNavigationBar>
  );
}
