// @ts-nocheck
import * as yup from 'yup';
import qs from 'query-string';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMemo, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Dayjs } from 'dayjs';
import { Box, Divider, Grid, Stack, Snackbar, Typography } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
  useDeleteDisplayData,
  useDisplayFormInput,
  useGetDataInputPullAttributeMapping,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { Formik, FormikProps } from 'formik';
import { generateYupSchema } from 'utils/SchemaGenerator';
import { generateFormInput } from './components/FormGenerator';
import ActivityIndicator from 'components/ActivityIndicatorComponent';
import { serializeDataInputPayload } from './utils/serialiazePayload';
import { RULES_CONSTANT } from 'constant/RuleConstant';
import { useUpdateDisplayData } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { RenderDataTableError } from 'components/DatatableComponent/components/DataTableError';
import { getErrorMessage, getErrorTitle } from 'utils/Error';
import ButtonLoading from '@mui/lab/LoadingButton';
import {
  generateBreadCumbItemsV2,
  TenantHomeDashboardNavigationBar,
} from 'components/LayoutComponent/VerticalLayout/HeaderBar';
import ModalDeleteComponent, { ModalDeleteState } from 'components/ModalComponent/ModalDeleteComponent';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { DISABLED_EDIT_DELETE_SUBJECTS } from 'constant/SubjectConstant';
import { CustomRuleCondition } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { ModalPreviewAttachment } from './components/ModalAttachmentContent/ModalPreviewAttachment';
import eventEmitter, { EVENT_EMITTER_KEYS } from 'utils/EventEmitter';
import { useGetBreadcumbItems } from 'services/v1/Common/NavBarMenuService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';

export default function UpdateDataInput() {
  const { subjectId, recordId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const procedureId = searchParams.get('procedureId') || undefined;
  const actionParams = searchParams.get('action') || undefined;
  const isRecordRestore = actionParams === 'RESTORE_RECORD';
  const location = useLocation();
  const existingSearchParams = qs.parse(location.search);
  const authReducer = useSelector((state: RootState) => state.auth);
  const formikRef = useRef<FormikProps<any>>(null);
  const { data: subjectDetails, error: errorSubjectDetails } = useGetSubjectDetails(subjectId);

  const [displayKey, setDisplayKey] = useState<number>(0);
  const [modalDeleteState, setModalDeleteState] = useState<ModalDeleteState>({
    message: 'Are you sure you want to delete this data?',
    open: false,
  });
  const { mutate: updateData, isLoading: isInserting } = useUpdateDisplayData({
    subjectId: subjectId as string,
    procedureId,
    isRestoreRecord: isRecordRestore,
  });

  const [modalAttachment, setModalAttachment] = useState<{
    visible: boolean;
    url: string | undefined;
    fieldName: string;
    fieldLabel: string;
  }>({
    visible: false,
    url: undefined,
    fieldName: '',
    fieldLabel: '',
  });
  const { mutate: deleteData } = useDeleteDisplayData({ subjectId: subjectId as string });
  const {
    data: formDefinitionsData,
    isLoading,
    isFetching,
    isError: isFetchFormError,
    error: errorFetchForm,
  } = useDisplayFormInput({ subjectId, recordId, procedureId });
  const { data: breadCrumbItems } = useGetBreadcumbItems(subjectId, NAVIGATION_COMPONENT_TYPE.SUBJECT);
  const { data: pullAttributeDataMapping } = useGetDataInputPullAttributeMapping(subjectId);
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);

  const error = errorFetchForm || errorSubjectDetails;

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleOnSubmitForm = (data: { [key: string]: any }, redirectPath: string) => {
    // map data with ruleTypeCode from formDefinitionsData
    // then send to API

    const dataWithRuleTypeCode: {
      [key: string]: { ruleTypeCode: string; value: string | number | object | Dayjs };
    } = _.mapValues(data, (value, key) => {
      if (formDefinitionsData) {
        const fieldItem = _.find(formDefinitionsData.fields, { name: key });
        return { value, ruleTypeCode: fieldItem.ruleTypeCode };
      }
    });

    const payload = {
      ...serializeDataInputPayload(dataWithRuleTypeCode, authReducer),
      id: recordId,
    };

    const tableDefinitionFieldId = Object.keys(serializeDataInputPayload(dataWithRuleTypeCode, authReducer)).reduce(
      (acc, key) => {
        const fieldItem = _.find(formDefinitionsData.fields, { name: key });
        return { ...acc, [key]: fieldItem.tableDefinitionId };
      },
      {}
    );

    const baseAttributeSubject = _.find(dataWithRuleTypeCode, {
      ruleTypeCode: RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
    });
    const memberList = baseAttributeSubject?.value;

    updateData(
      { data: payload, tableDefinitionId: tableDefinitionFieldId, memberList },
      {
        onSuccess: () => {
          toast.success('Data updated successfully!');
          formikRef.current?.resetForm();
          setDisplayKey(displayKey + 1);
          handleOnCancelInput();
        },
        onError: (err) => {
          toast.error(getErrorMessage(err));
        },
      }
    );
  };

  const handleOnShowDeleteConfirmation = () => {
    const fieldBaseSubject = formDefinitionsData?.fields.find(
      (fieldItem) => fieldItem.ruleTypeCode === RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE
    );
    const value = formikRef.current?.values[fieldBaseSubject?.name]?.label;
    const message = `Are you sure you want to delete <strong>${fieldBaseSubject?.label} ${value}</strong>?`;

    setModalDeleteState({ ...modalDeleteState, open: true, message });
  };

  const handleOnDeleteData = () => {
    deleteData(
      { subjectId, ids: [recordId] },
      {
        onSuccess: () => {
          toast.success('Data deleted successfully!');
          setModalDeleteState({ ...modalDeleteState, open: false });
          navigate(`${PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(':subjectId', subjectId)}?sid=${subjectId}`);
        },
        onError: (error: AxiosDefaultErrorEntity) => {
          toast.error(getErrorMessage(error));
          setModalDeleteState({
            open: true,
            message: '',
            isError: true,
            errorMessage: getErrorMessage(error),
          });
        },
      }
    );
  };

  const handleOnCancelInput = () => {
    if (isRecordRestore) {
      const { action, arcRecord, ...restParams } = existingSearchParams;
      const redirectPath = `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT_DEDICATED_PAGE_INSERT.replace(
        ':subjectId',
        subjectId
      )}?${qs.stringify(restParams)}`;
      navigate(redirectPath);
    } else {
      const redirectPath = decodeURIComponent(searchParams.get('refBackParam')) || PATH_CONSTANT.TENANT.KMS.DATA_INPUT;
      navigate(redirectPath);
    }
  };

  const yupSchema = useMemo(() => {
    if (!formDefinitionsData) {
      return {};
    }
    return formDefinitionsData.fields.reduce(generateYupSchema, {});
  }, [formDefinitionsData]);

  const validateSchema = useMemo(() => {
    return yupSchema ? yup.object().shape(yupSchema) : {};
  }, [yupSchema]);

  // Seperate fields into 2 columns
  const formFields = useMemo(() => {
    if (!formDefinitionsData) {
      return {
        input: [],
        multimedia: [],
      };
    }
    const ruleToSeperate = [
      RULES_CONSTANT.GPS.RULE_GPS,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIST,
      RULES_CONSTANT.ASSOCIATED_SUBJECT.RULE_ASSOCIATED_SUBJECT_LIMITED_DROPDOWN_LIST,
      RULES_CONSTANT.TEXT.RULE_SELECT_FROM_KTREE_TABLE,
      RULES_CONSTANT.BASE_SUBJECT.RULE_ATTRIBUTE_SUBJECT_BASE,
      ...Object.values(RULES_CONSTANT.DATE),
    ];

    return {
      input: formDefinitionsData.fields.filter((fieldItem, index) => {
        return ruleToSeperate.includes(fieldItem.ruleTypeCode);
      }),
      multimedia: formDefinitionsData.fields.filter((fieldItem, index) => {
        return !ruleToSeperate.includes(fieldItem.ruleTypeCode);
      }),
    };
  }, [formDefinitionsData]);

  const breadCrumbs = useMemo(() => {
    return generateBreadCumbItemsV2(breadCrumbItems?.data);
  }, [breadCrumbItems?.data]);

  const mapCustomRuleWithField: Map<string, CustomRuleCondition> = useMemo(() => {
    const customRule = new Map();
    if (!formDefinitionsData) {
      return customRule;
    }

    Object.entries(formDefinitionsData.customRule).forEach(([key, value]) => {
      customRule.set(key, value);
    });
    return customRule;
  }, [formDefinitionsData]);

  const tableTitle = useMemo(() => {
    if (!subjectDetails?.data) return '';
    return `Edit ${subjectDetails?.data?.displayName}`;
  }, [subjectDetails?.data]);

  const isCoreSubject = useMemo(() => {
    return DISABLED_EDIT_DELETE_SUBJECTS.includes(subjectId);
  }, [subjectId]);

  const handleOnChangeAttachment = (fieldName: string, value: string) => {
    formikRef.current?.setFieldValue(fieldName, value);
  };

  useEffect(() => {
    const handleOnShowAttachmentClick = (data: { visible: boolean; url: string; fieldName: string }) => {
      setModalAttachment(data);
    };
    eventEmitter.on(EVENT_EMITTER_KEYS.TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT, handleOnShowAttachmentClick);

    return () => {
      eventEmitter.off(EVENT_EMITTER_KEYS.TIRGGER_SHOW_MODAL_ATTACHMENT_INSERT, handleOnShowAttachmentClick);
    };
  }, []);

  const initialObjectIds = useMemo(() => {
    return modalAttachment.url?.split(',') || [];
  }, [modalAttachment.url]);

  return (
    <TenantHomeDashboardNavigationBar error={error} breadCrumbs={breadCrumbs}>
      <Box sx={{ height: '100%', backgroundColor: '#fff', pb: 3 }}>
        {isCoreSubject && (
          <Alert sx={{ mb: 2 }} severity='warning'>
            This record is essential to the core architecture of the platform and is integrated at the code level. As
            such, it cannot be edited or deleted.
          </Alert>
        )}

        {(isLoading || isFetching) && (
          <Stack height={500}>
            <ActivityIndicator />
          </Stack>
        )}

        {isFetchFormError && (
          <RenderDataTableError
            title={getErrorTitle(errorFetchForm)}
            description={errorFetchForm && getErrorMessage(errorFetchForm)}
          />
        )}

        {!isFetchFormError && !isFetching && formDefinitionsData && (
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={formDefinitionsData.initialValues}
            validationSchema={validateSchema}
            onSubmit={handleOnSubmitForm}
          >
            {(formikProps: FormikProps<{ [key: string]: string }>) => {
              return (
                <Box sx={{ backgroundColor: '#fff', pb: 1, px: 3 }} alignItems='center' key={displayKey}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    py={1}
                    justifyContent='space-between'
                    sx={{ backgroundColor: '#fff' }}
                    spacing={2}
                  >
                    <Stack>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        {tableTitle}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Typography variant='input-label' fontStyle='italic' sx={{ pt: 1.3, fontSize: 13 }}>
                      (<span style={{ color: 'red' }}>*</span>) indicates required fields
                    </Typography>
                  </Stack>

                  <Grid
                    container
                    marginTop={2}
                    sx={{ backgroundColor: '#fff', py: 1, borderTop: '1px solid #E3EBF6' }}
                    justifyContent='space-between'
                    alignItems='flex-start'
                  >
                    <Grid container item xs={6} direction='column'>
                      {formFields?.input?.map((fieldItem) =>
                        generateFormInput(
                          formikProps,
                          fieldItem,
                          true,
                          mapCustomRuleWithField.get(fieldItem.name),
                          pullAttributeDataMapping?.data
                        )
                      )}
                    </Grid>

                    {formFields.multimedia.length > 0 && (
                      <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
                    )}

                    <Grid container item xs={5} direction='column'>
                      {formFields?.multimedia?.map((fieldItem) =>
                        generateFormInput(
                          formikProps,
                          fieldItem,
                          true,
                          mapCustomRuleWithField.get(fieldItem.name),
                          pullAttributeDataMapping?.data
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Stack spacing={2} direction='row' justifyContent='flex-end'>
                    {!isRecordRestore && (
                      <Button
                        disabled={isInserting || isCoreSubject}
                        onClick={handleOnShowDeleteConfirmation}
                        variant='outlined'
                        sx={{
                          textTransform: 'none',
                          '&:disabled': {
                            cursor: 'not-allowed',
                          },
                        }}
                        color='error'
                      >
                        Delete
                      </Button>
                    )}
                    <Button disabled={isInserting} onClick={handleOnCancelInput} variant='main-table-panel-border'>
                      Cancel
                    </Button>
                    <ButtonLoading
                      variant='main-table-panel'
                      onClick={() => formikProps.handleSubmit()}
                      loading={isInserting}
                      // @ts-ignore
                      disabled={!validateSchema?.isValidSync(formikProps.values) || isInserting || isCoreSubject}
                    >
                      {isRecordRestore ? 'Save & Restore' : 'Update'}
                    </ButtonLoading>
                  </Stack>
                </Box>
              );
            }}
          </Formik>
        )}
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}

        <ModalDeleteComponent
          errorMessage={modalDeleteState.errorMessage}
          isError={modalDeleteState.isError}
          visible={modalDeleteState.open}
          title={null}
          message={modalDeleteState.message}
          onApprove={handleOnDeleteData}
          onClose={() => setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: null })}
          onCancel={() => setModalDeleteState({ ...modalDeleteState, open: false, isError: false, errorMessage: null })}
        />
      </Box>
      <ModalPreviewAttachment
        visible={modalAttachment.visible}
        subjectId={subjectId}
        viewOnly={false}
        fieldName={modalAttachment.fieldName}
        fieldLabel={modalAttachment.fieldLabel}
        initialObjectIds={initialObjectIds}
        onChange={handleOnChangeAttachment}
        onClose={() => {
          setModalAttachment({ visible: false, url: undefined });
        }}
      />
    </TenantHomeDashboardNavigationBar>
  );
}
