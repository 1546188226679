import { NestedMenuItem } from 'mui-nested-menu';
import { Box, List, ListItem, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useGetMyPersonalization } from 'services/v1/Tenant/ConfigurationService';
import { NAVIGATION_COMPONENT_TYPE } from 'constant/NavigationConstant';
import { MyViewDashboardItem, QuickAccessItem } from 'types/api/Tenant/ConfigurationTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { getQuickAccessNavigateURL } from './QuickAccess';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { NAV_ITEM_TYPE } from './HeaderBar';

export type BreadCumbItemType = {
  label: string;
  type: string;
  id: string;
};

type AutoCompleteItemWithType = AutoCompleteItem & { type: string };
type SolutionOptionItem = AutoCompleteItemWithType & { childrens?: SolutionOptionItem[] };
type MenuOptionValue = SolutionOptionItem[] | MyViewDashboardItem[] | QuickAccessItem[];

const MyViewItemComponent = new Set([
  NAVIGATION_COMPONENT_TYPE.CHART,
  NAVIGATION_COMPONENT_TYPE.CUBE,
  NAVIGATION_COMPONENT_TYPE.EVENT,
  NAVIGATION_COMPONENT_TYPE.SUBJECT,
  NAVIGATION_COMPONENT_TYPE.K_TREE,
  NAVIGATION_COMPONENT_TYPE.STANDARD_VIEW,
  NAVIGATION_COMPONENT_TYPE.INSIGHT_VIEW,
]);

const NestedMenuItemStyle = {
  '& .MuiBox-root .MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&.Mui-selected': {
      textDecoration: 'underline',
    },
  },
};
const breadCumbItemTextStyle = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 500,
  color: '#fff',
};

const breadCumbListContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '1px',
  color: '#fff',
  flexDirection: 'row',
  p: 0,
};

const breadCumbContainerStyle = {
  backgroundColor: '#3B4797',
  height: '33px',
  display: 'flex',
  zIndex: -1,
  px: 3,
};

const MenuItemStyle = {
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&.Mui-selected': {
    textDecoration: 'underline',
  },
};

export function NavBarStatusBar(props: {
  breadCumbItems?: BreadCumbItemType[];
  selectedBreadCumbItem?: BreadCumbItemType;
  onBreadCumbItemClick?: (item: BreadCumbItemType) => void;
}) {
  const { breadCumbItems = [] } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');
  const { role } = useSelector((state: RootState) => state.auth);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentItem, setCurrentItem] = useState<BreadCumbItemType | null>(null);

  const handleOnSubjectClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: BreadCumbItemType) => {
    if (item.type === NAV_ITEM_TYPE.SUBJECT.toUpperCase() && !myViewId) {
      const navigateUrl = PATH_CONSTANT.TENANT.KMS.DATA_INPUT.replace(':subjectId', item.id);
      navigateUrl && navigate(navigateUrl);
      return;
    }

    setAnchorEl(event.currentTarget);
    setCurrentItem(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentItem(null);
  };

  const menuOptionsMap: Map<string, MenuOptionValue> = useMemo(() => {
    const map = new Map<string, MenuOptionValue>();

    if (allMyViewsData?.data.solutions) {
      const solutions: SolutionOptionItem[] = allMyViewsData.data.solutions.map((solution) => {
        const dashboards = allMyViewsData.data.dashboards.filter((dsb) => dsb.solutionDefinition.id === solution.value);
        const childrens: SolutionOptionItem[] = dashboards.map((dashboard) => {
          return {
            label: dashboard.dashboardName,
            value: dashboard.id,
            type: 'MY_VIEW_DASHBOARD',
          };
        });
        return { ...solution, childrens, type: 'SOLUTION' };
      });

      map.set('SOLUTION', solutions);
    }
    const currentSolution = breadCumbItems.find((item) => item.type === 'SOLUTION');
    const currentDasboard = breadCumbItems.find((item) => item.type === 'MY_VIEW_DASHBOARD');
    const dashboardLists =
      allMyViewsData?.data.dashboards.filter((dashboard) => dashboard.solutionDefinition.id === currentSolution?.id) ||
      [];

    const currentDashboardListOptions: SolutionOptionItem[] = dashboardLists.map((dashboard) => {
      return {
        label: dashboard.dashboardName,
        value: dashboard.id,
        type: 'MY_VIEW_DASHBOARD',
      };
    });
    map.set('MY_VIEW_DASHBOARD', currentDashboardListOptions);

    const myVIewItems =
      allMyViewsData?.data.dashboards.find((dashboard) => dashboard.id === currentDasboard?.id)?.myViews ?? [];

    const currentMyViewItemListOptions: SolutionOptionItem[] = myVIewItems.map((myView) => {
      return {
        label: myView.title,
        value: myView.value,
        childrens: [],
        type: 'MY_VIEW_ITEM',
      };
    });

    map.set('MY_VIEW_ITEM', currentMyViewItemListOptions);

    return map;
  }, [allMyViewsData?.data, breadCumbItems]);

  const mapMyViewIdToMyView: Map<string, QuickAccessItem> = useMemo(() => {
    const map = new Map<string, QuickAccessItem>();
    if (allMyViewsData?.data) {
      allMyViewsData.data.dashboards.forEach((dashboard) => {
        dashboard.myViews.forEach((myView) => {
          map.set(myView.value, myView);
        });
      });
    }
    return map;
  }, [allMyViewsData?.data]);

  const mapDashboardIdToMyViewDashboard: Map<string, MyViewDashboardItem> = useMemo(() => {
    const map = new Map<string, MyViewDashboardItem>();
    if (allMyViewsData?.data) {
      allMyViewsData.data.dashboards.forEach((dashboard) => {
        map.set(dashboard.id, dashboard);
      });
    }
    return map;
  }, [allMyViewsData?.data]);

  const handleNavigateMyView = (myViewItem: QuickAccessItem) => {
    const componentURL = getQuickAccessNavigateURL(myViewItem, undefined, PAGE_REF_NAVIGATION.LEFT, role);
    handleCloseMenu();
    componentURL && navigate(componentURL);
  };

  const handleNavigateMyViewDashboard = (myViewDashboard: MyViewDashboardItem) => {
    const myViewItem = myViewDashboard.myViews[0];
    handleNavigateMyView(myViewItem);
  };

  const handleOnMenuClick = (option: SolutionOptionItem) => {
    if (option.type === 'MY_VIEW_ITEM') {
      const myViewItem = mapMyViewIdToMyView.get(option.value?.toString());
      !!myViewItem && handleNavigateMyView(myViewItem);
    }

    if (option.type === 'MY_VIEW_DASHBOARD') {
      const myViewDashboard = mapDashboardIdToMyViewDashboard.get(option?.value?.toString());
      !!myViewDashboard && handleNavigateMyViewDashboard(myViewDashboard);
    }
  };

  const renderMenuItems = (options: SolutionOptionItem[]) => {
    return options.map((option) => {
      if (option.childrens && option.childrens.length > 0) {
        return (
          <NestedMenuItem
            onClick={() => handleOnMenuClick(option)}
            key={option.value}
            label={option.label}
            sx={NestedMenuItemStyle}
            parentMenuOpen
          >
            {renderMenuItems(option.childrens)}
          </NestedMenuItem>
        );
      }
      return (
        <MenuItem key={option.value} onClick={() => handleOnMenuClick(option)} sx={MenuItemStyle}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const renderOptionForSolution = (options: SolutionOptionItem[]) => {
    return renderMenuItems(options);
  };

  return (
    <Box sx={breadCumbContainerStyle}>
      <List sx={breadCumbListContainerStyle}>
        {breadCumbItems.map((item, index) => {
          const isLastItem = breadCumbItems.length - 1 === index;
          const isSubject = item.type === NAV_ITEM_TYPE.SUBJECT.toUpperCase();
          const isHiglightSubjectAsMenu = isSubject && !isLastItem;

          return (
            <ListItem
              key={item.label}
              sx={{
                height: '100%',
                width: 'auto',
                px: 1.2,
                bgcolor: isLastItem ? '#4D5AAE' : 'transparent',
                cursor: isHiglightSubjectAsMenu ? 'pointer' : 'default',
                textDecoration: isHiglightSubjectAsMenu ? 'underline' : 'none',
                '&:hover': {
                  cursor: Boolean(myViewId) ? 'pointer' : 'default',
                  textDecoration: Boolean(myViewId) ? 'underline' : 'none',
                },
              }}
              onClick={(e) => handleOnSubjectClick(e, item)}
            >
              <ListItemText primary={<Typography sx={breadCumbItemTextStyle}>{item.label}</Typography>} />
            </ListItem>
          );
        })}
      </List>
      {/** Menu to show sub-options if the clicked item has them */}
      {currentItem && Boolean(myViewId) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl && currentItem)}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {!MyViewItemComponent.has(currentItem.type as NAVIGATION_COMPONENT_TYPE) &&
            renderOptionForSolution(menuOptionsMap.get(currentItem.type) as SolutionOptionItem[])}

          {MyViewItemComponent.has(currentItem.type as NAVIGATION_COMPONENT_TYPE) &&
            renderOptionForSolution(menuOptionsMap.get('MY_VIEW_ITEM') as SolutionOptionItem[])}
        </Menu>
      )}
    </Box>
  );
}
