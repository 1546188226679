import * as React from 'react';
import { toast } from 'react-toastify';
import { GridRenderCellParams, GridColDef, useGridApiContext } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDataTypesDropdown, useRuleTypesDropdown } from 'services/v1/SystemTenant/AROKMS/TableDefinitionService';
import {
  useAttributeDropdownTableDefinition,
  useUpsertAttributeData,
} from 'services/v1/SystemTenant/AROKMS/AttributeService';
import { useSubjectDropdownTableDefinition } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { useGetCubeLayerSetDropdown, useUpsertCubeLayerSet } from 'services/v1/SystemTenant/AROCube/CubeLayerService';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { getErrorMessage } from 'utils/Error';
import { AxiosDefaultErrorEntity } from 'types';
import { useGetAllKTreeDefinitionList } from 'services/v1/SystemTenant/AROKMS/KTreeBuilderService';

const autocompleteStyles = {
  width: '95%',
  '& .MuiOutlinedInput-root': {
    padding: '1px 2px',
  },
  '& .MuiAutocomplete-popper': {
    fontSize: '10px',
  },
};

const textInputStyles = {
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const optionStyles = { '& > span': { fontSize: '14px', mr: 2, flexShrink: 0 } };

function SelectEditInputCell(
  props: GridRenderCellParams & {
    parentSubjectId?: string | number;
    selectedSubjectId?: string | number;
    selectedSubjectType?: string;
    showAllRuleType?: boolean;
    optionsSource?: DropdownItem[];
    deletable?: boolean;
  }
) {
  const {
    id,
    value,
    field,
    selectedSubjectId,
    showAllRuleType = false,
    optionsSource = null,
    deletable = true,
  } = props;

  const isFreeSolo = field === 'attribute' || field === 'layerSet';

  const ATTRIBUTE_TOAST_ID = 'ATTRIBUTE_TOAST_ID';

  const { data: dataTypesDropdown } = useDataTypesDropdown({ subjectType: props.selectedSubjectType });
  const { data: ruleTypesDropdown } = useRuleTypesDropdown({ subjectType: props.selectedSubjectType });
  const { data: attributeDropdown } = useAttributeDropdownTableDefinition(selectedSubjectId);
  const { data: ktreeDefintionDropdown } = useGetAllKTreeDefinitionList();
  const { data: subjectDropdown } = useSubjectDropdownTableDefinition(selectedSubjectId);
  const { data: cubeLayerSetDropdown } = useGetCubeLayerSetDropdown();
  const { mutateAsync: upsertAttributeAsync } = useUpsertAttributeData();

  const { mutateAsync: upsertLayerSetAsync } = useUpsertCubeLayerSet();

  const getDropdownOptions = (field: string) => {
    switch (field) {
      case 'dataType':
        return dataTypesDropdown?.data;
      case 'ruleType':
        // eslint-disable-next-line eqeqeq
        if (showAllRuleType) return ruleTypesDropdown?.data;
        // eslint-disable-next-line eqeqeq
        return ruleTypesDropdown?.data?.filter((item) => item?.dataType?.id == props.row.dataType.id);
      case 'attribute':
        return attributeDropdown?.data;
      case 'associatedSubject':
        return subjectDropdown?.data.map((item) => ({ value: item.id, label: item.subject }));
      case 'ktreeDefinition':
        return ktreeDefintionDropdown?.data;

      case 'layerSet':
        return cubeLayerSetDropdown?.data;
      default:
        return [];
    }
  };

  const apiRef = useGridApiContext();

  const filter = createFilterOptions<{ value: string; label: string; isNew?: boolean }>();

  const handleChange = async (
    event: React.SyntheticEvent,
    value: { label: string; value: string; isNew?: boolean }
  ) => {
    try {
      if (deletable && !value) {
        await apiRef.current.setEditCellValue({ id, field, value: value });
        apiRef.current.stopCellEditMode({ id, field });
        return;
      }
      if (value.isNew) {
        if (field === 'attribute') {
          toast(`Adding new attribute "${value.label}"...`, {
            toastId: ATTRIBUTE_TOAST_ID,
            isLoading: true,
            updateId: ATTRIBUTE_TOAST_ID,
          });
          const { data } = await upsertAttributeAsync({ attribute: value.value });
          await apiRef.current.setEditCellValue({ id, field, value: { label: data.attribute, value: data.id } });
          toast.update(ATTRIBUTE_TOAST_ID, {
            type: 'success',
            render: `Your new attribute was inserted successfully!`,
            isLoading: false,
            autoClose: 4000,
          });
          apiRef.current.stopCellEditMode({ id, field });
        }
        if (field === 'layerSet') {
          const { data } = await upsertLayerSetAsync({ name: value.value });
          await apiRef.current.setEditCellValue({ id, field, value: { label: data.name, value: data.id } });
          apiRef.current.stopCellEditMode({ id, field });
        }
      } else {
        if (value) {
          await apiRef.current.setEditCellValue({ id, field, value: value });

          // If current changes to dataType, then ruleType should be reset to null if it is not match with new dataType
          if (field === 'dataType' && props.row?.ruleType?.id) {
            // eslint-disable-next-line eqeqeq
            const findCurrentRuleType = ruleTypesDropdown?.data?.find((item) => item?.id == props.row?.ruleType?.id);
            if (findCurrentRuleType && findCurrentRuleType?.dataType.id !== +value?.value) {
              await apiRef.current.setEditCellValue({ id, field: 'ruleType', value: { label: '', value: '' } });
            }
          }

          apiRef.current.stopCellEditMode({ id, field });
        }
      }
    } catch (error) {
      toast.update(ATTRIBUTE_TOAST_ID, {
        type: 'error',
        render: getErrorMessage(error as AxiosDefaultErrorEntity),
        isLoading: false,
      });
    }
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        if (option.isNew) {
          return option.value;
        }
        // Regular option
        return option.label || '';
      }}
      selectOnFocus
      freeSolo={isFreeSolo}
      filterOptions={(options, params) => {
        if (!isFreeSolo) {
          const filtered = filter(options, params);
          return filtered;
        } else {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue.toLowerCase() === option.label.toLowerCase());

          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              label: `Add "${inputValue}"`,
              isNew: true,
            });
          }

          return filtered;
        }
      }}
      disabled={field === 'ruleType' && !props.row?.dataType?.id && !showAllRuleType}
      contentEditable={false}
      value={value}
      defaultValue={{ label: value?.label || '', value: value?.value || '' }}
      options={optionsSource ? optionsSource : getDropdownOptions(field) || []}
      renderOption={(props, option) => (
        <Box component='li' sx={optionStyles} {...props}>
          <span>{option?.label}</span>
        </Box>
      )}
      sx={autocompleteStyles}
      // @ts-ignore
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} sx={textInputStyles} />}
    />
  );
}

type RenderSelectEditInputCellParams = GridColDef['renderCell'] & {
  parentSubjectId?: string | number;
  seletedSubjectId?: string | number;
  selectedSubjectType?: string | number;
  showAllRuleType?: boolean;
  optionsSource?: DropdownItem[];
  deletable?: boolean;
};

const MemoizedSelectInputCell = React.memo(SelectEditInputCell);
export const renderSelectEditInputCell: RenderSelectEditInputCellParams = (params) => {
  return <MemoizedSelectInputCell {...params} key={params?.id} />;
};
