import { useState, useEffect, useMemo } from 'react';
import { useIsMutating } from 'react-query';
import Stack from '@mui/material/Stack';
import Typograhpy from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { GridRowModesModel, GridRowsProp, GridRowModes, useGridApiContext } from '@mui/x-data-grid-pro';
import { ButtonExport, FileType } from 'components/ButtonComponent/ButtonExport';
import { ButtonDownloadTemplate } from 'components/ButtonComponent/ButtonDownloadTemplate';
import { ButtonImport } from 'components/ButtonComponent/ButtonImport';
import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';
import { RoleBasedAccessProvider } from 'components/RBAC';
import ButtonLoading from '@mui/lab/LoadingButton';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import { dayjs } from 'utils/DateUtils';

// Custom Icon
import { ROLES } from 'constant/PermissonConstant';
import {
  ButtonTableOptions,
  DataInputTableOptions,
  EventTableOptions,
} from 'components/ButtonComponent/ButtonTableOptions';
import { useGetLastSyncHistory } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS } from 'constant/DataInputConstant';
import { SystemStatusBasedAccessProvider } from 'components/RBAC/SystemStatusBasedAccessProvider';
import { isImportingData } from 'pages/Tenant/AROKMS/DataInput/utils/fileImport';
import { useGetCurrentImportActivity } from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { useGetCurrentCubeSyncEventActivity } from 'services/v1/Tenant/AROEvent/EventTableService';
import { DataInputImportActivity, DataInputImportActivityStatus } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';
import { generateColumnNames, handleExport } from 'utils/ExportUtils';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { getCurrentMyViewIndex, getNextMyViewItem, getPreviousMyViewItem } from 'utils/MyViewUtils';
import { useDashboardNaviagationPrefetchHandler } from 'hooks/useDashboardNavigationPrefetchHandler';
import { Alert } from '@mui/material';
import { ENV_CONFIG } from 'config/env';
interface SettingPanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
}

interface OptionItem {
  value: string;
  label: string;
}

interface EventSettingPanelProps {
  onDeleteData?: () => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  config: SettingPanelConfigProps;
  isInserting: boolean;
  title: string;
  options: OptionItem[];
  initialOption?: OptionItem | undefined;
  isConnectedToDataSource?: boolean;
  viewOnly?: boolean;
  withChipLabel?: boolean;
  optionValue: OptionItem;
  disabled: boolean;
  showSyncButton?: boolean;
  onSync?: () => void;
  totalDependentCubes?: number;
  onOptionChange: (value: OptionItem) => void;
  onExport: (selectedFileType: FileType) => void;
  onImport: (selectedFileType: FileType) => void;
  onButtonTemplateClick: (selectedFileType: FileType) => void;
  disabledDeleteButton?: boolean;
  onAddDataClick?: () => void;
  onOptionClick?: (type: EventTableOptions) => void;
}

const buttonActionStyle = {
  padding: 1.4,
};

function getLoadingDescription(importActivityData: DataInputImportActivity) {
  const channelName = importActivityData.channelName;
  switch (importActivityData?.status) {
    case DataInputImportActivityStatus.ON_PROGRESS:
      return channelName
        ? `Importing ${importActivityData?.totalCurrentProcessedRecords ?? 0} of ${
            importActivityData?.totalRecords ?? 0
          } records from ${channelName} ...`
        : `Importing ${importActivityData?.totalCurrentProcessedRecords ?? 0} of ${
            importActivityData?.totalRecords ?? 0
          } records ...`;

    case DataInputImportActivityStatus.READING_DATA:
      return channelName
        ? `Reading ${importActivityData.totalCurrentReadingRecords} of ${importActivityData.totalRecords} records from ${channelName} ...`
        : `Reading ${importActivityData.totalCurrentReadingRecords} of ${importActivityData.totalRecords} records ...`;
    case DataInputImportActivityStatus.CONNECTING:
      return `Connecting to data channel ...`;
    case DataInputImportActivityStatus.VALIDATING_DATA:
      return channelName
        ? `Validating ${importActivityData.totalCurrentValidatingRecords} of ${importActivityData.totalRecords} records from ${channelName} ...`
        : `Validating ${importActivityData.totalCurrentValidatingRecords} of ${importActivityData.totalRecords} records ...`;
    default:
      return 'Starting import process ...';
  }
}

export function EventSettingPanel(props: EventSettingPanelProps) {
  const isMutating = Boolean(useIsMutating());

  const {
    setFilterButtonElement,
    setRows,
    setRowModesModel,
    config,
    disabled,
    viewOnly,
    onImport,
    onButtonTemplateClick,
    onAddDataClick = undefined,
    showSyncButton,
    totalDependentCubes,
    onSync,
    onOptionClick = () => {},
  } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');
  const { subjectId } = useParams<{ subjectId: string }>();
  const { selectedTenant, role } = useSelector((state: RootState) => state.auth);

  const apiRef = useGridApiContext();
  const [isConnectedToDataSource, setIsConnectedToDataSource] = useState<boolean>(false);

  const { data: lastSyncEventData } = useGetLastSyncHistory(subjectId, 0);
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);
  const { data: importActivity } = useGetCurrentImportActivity(subjectId);
  const { data: syncCubeActivityStatus } = useGetCurrentCubeSyncEventActivity(subjectId);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);

  const prefetchHandler = useDashboardNaviagationPrefetchHandler();

  const insightViewList = useMemo(() => {
    if (allMyViewsData?.data) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;

      if (selectedDashboard) {
        return selectedDashboard.myViews;
      }
    }

    return [];
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const currentMyViewIndex = useMemo(() => {
    if (myViewId == null) return 0;
    return getCurrentMyViewIndex(insightViewList, myViewId);
  }, [myViewId, insightViewList]);

  const nextViewItem = useMemo(() => {
    return getNextMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const previousViewItem = useMemo(() => {
    return getPreviousMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const isOnImportActivityProgress = useMemo(() => {
    if (importActivity?.data?.status) {
      return isImportingData(importActivity?.data?.status);
    }
    return false;
  }, [importActivity?.data?.status]);

  const isSyncinCubeActivityProgress = useMemo(() => {
    return Boolean(syncCubeActivityStatus?.data?.status);
  }, [syncCubeActivityStatus?.data?.status]);

  const { fieldFocusOnInsert = '', insertButtonText = 'Add', insertInitialObject = {} } = config;

  useEffect(() => {
    if (lastSyncEventData?.data) {
      setIsConnectedToDataSource(
        Boolean(
          lastSyncEventData?.data?.dataSourceConfig &&
            lastSyncEventData?.data?.dataSourceConfig.status === EVENT_SYNC_DATA_SOURCE_CONFIG_STATUS.CONNECTED
        )
      );
    }
  }, [lastSyncEventData, isConnectedToDataSource]);

  const handleAddData = () => {
    if (onAddDataClick) {
      onAddDataClick();
    } else {
      const id = new Date().getTime();
      setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
      }));
    }
  };

  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    switch (direction) {
      case ControllerNavigationDirection.LEFT:
        if (previousViewItem) {
          const previousURL = getQuickAccessNavigateURL(previousViewItem, undefined, PAGE_REF_NAVIGATION.LEFT, role);
          previousURL && navigate(previousURL);
        }
        break;
      case ControllerNavigationDirection.RIGHT:
        if (nextViewItem) {
          const nextURL = getQuickAccessNavigateURL(nextViewItem, undefined, PAGE_REF_NAVIGATION.RIGHT, role);
          nextURL && navigate(nextURL);
        }
        break;
      default:
        break;
    }
  };

  const handleOnDashboardNavigatePrefetch = (direction: ControllerNavigationDirection) => {
    switch (direction) {
      case ControllerNavigationDirection.LEFT:
        if (previousViewItem) {
          const afterPreviousItemIndex = currentMyViewIndex - 2;
          const afterPreviousItem = insightViewList?.[afterPreviousItemIndex] || undefined;
          prefetchHandler(previousViewItem, afterPreviousItem);
        }
        break;
      case ControllerNavigationDirection.RIGHT:
        if (nextViewItem) {
          const afterNextItemIndex = currentMyViewIndex + 2;
          const afterNextItem = insightViewList?.[afterNextItemIndex] || undefined;
          prefetchHandler(nextViewItem, afterNextItem);
        }
        break;
      default:
        break;
    }
  };

  const tableMenuOptionsId = useMemo(() => {
    if (isConnectedToDataSource)
      return [
        EventTableOptions.MODIFY_DATA_SOURCE_CONNECTION,
        DataInputTableOptions.IMPORT_DATA_HISTORY,
        EventTableOptions.MANUAL_SYNC_TO_CUBE,
      ];
    else
      return [
        EventTableOptions.CONNECT_DATA_SOURCE,
        DataInputTableOptions.IMPORT_DATA_HISTORY,
        EventTableOptions.MANUAL_SYNC_TO_CUBE,
      ];
  }, [isConnectedToDataSource]);

  const handleExportCLick = (fileType: string) => {
    if (fileType === FILE_TYPE_PARAMS.EXCEL) {
      const exportConfig = {
        columnNames: generateColumnNames(apiRef),
        keys: generateColumnNames(apiRef),
        sheetName: 'Sheet1',
        fileName: `${subjectDetails?.data?.displayName}_${selectedTenant.tenant.tenantName}.xlsx`,
      };
      handleExport(apiRef, exportConfig);
    }
    if (fileType === FILE_TYPE_PARAMS.CSV) {
      apiRef.current.exportDataAsCsv({
        fileName: `${subjectDetails?.data?.displayName}_${selectedTenant.tenant.tenantName}.csv`,
      });
    }
  };

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {};
    if (insightViewList.length > 1) {
      if (currentMyViewIndex > -1) {
        if (currentMyViewIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${insightViewList[currentMyViewIndex - 1].title}`;
        }
        if (currentMyViewIndex < insightViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${
            insightViewList[currentMyViewIndex + 1].title
          }`;
        }
      }
    }
    return defaultToolTips;
  }, [currentMyViewIndex, insightViewList]);

  const controllerNaviations = useMemo(() => {
    const controller = [];
    if (insightViewList?.length > 1) {
      if (currentMyViewIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentMyViewIndex < insightViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }
    return controller;
  }, [currentMyViewIndex, insightViewList]);
  return (
    <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', py: 1.8 }}>
      <Stack direction='row' alignItems='center'>
        {insertButtonText && isConnectedToDataSource === false && (
          <RoleBasedAccessProvider
            allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
          >
            <SystemStatusBasedAccessProvider>
              {isConnectedToDataSource === false && !isOnImportActivityProgress && !isSyncinCubeActivityProgress && (
                <Typograhpy variant='subtitle1' sx={{ fontWeight: 600 }}>
                  <Button
                    disabled={disabled || isMutating}
                    variant='main-table-panel'
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={handleAddData}
                  >
                    {insertButtonText}
                  </Button>
                </Typograhpy>
              )}
            </SystemStatusBasedAccessProvider>
          </RoleBasedAccessProvider>
        )}
        {isConnectedToDataSource && (
          <Stack direction='row' color='GrayText' alignItems='center' gap={1} justifyContent='center'>
            {lastSyncEventData?.data?.dataSourceConfig?.logoUrl && (
              <>
                <img
                  src={lastSyncEventData?.data?.dataSourceConfig.logoUrl}
                  alt='logo'
                  style={{
                    maxWidth: 60,
                    height: 'auto',
                  }}
                />
                <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
              </>
            )}

            <Alert variant='outlined' severity='info'>
              <Stack>
                <Typograhpy variant='caption'>
                  This table's data is synced with the external source "
                  {lastSyncEventData?.data?.dataSourceConfig?.sourceTableName}"
                </Typograhpy>
                {lastSyncEventData?.data?.latestPullHistory?.lastPullDate && (
                  <Typograhpy variant='caption' fontSize={11} fontStyle='italic'>
                    Last updated on{' '}
                    {dayjs(lastSyncEventData?.data?.latestPullHistory?.lastPullDate).format(
                      ENV_CONFIG.config.DATE_TIME_FORMAT
                    )}
                  </Typograhpy>
                )}
              </Stack>
            </Alert>
          </Stack>
        )}
        {isOnImportActivityProgress && (
          <Stack direction='row' color='GrayText' alignItems='center'>
            <SyncAltOutlinedIcon sx={{ mr: 0.5 }} fontSize='small' />
            <Typograhpy variant='body2'>
              {getLoadingDescription(importActivity?.data as DataInputImportActivity)}
            </Typograhpy>
          </Stack>
        )}
        {isSyncinCubeActivityProgress && (
          <Stack direction='row' color='GrayText' alignItems='center'>
            <SyncAltOutlinedIcon sx={{ mr: 0.5 }} fontSize='small' />
            <Typograhpy variant='body2'>{`${syncCubeActivityStatus?.data?.message} ...`}</Typograhpy>
          </Stack>
        )}
      </Stack>

      <Stack direction='row' spacing={0} alignContent='center' alignItems='center'>
        <GridToolbarFilterButton
          ref={setFilterButtonElement}
          componentsProps={{
            button: {
              disabled: disabled,
              sx: buttonActionStyle,
              variant: 'main-table-panel-borderless',
            },
          }}
        />
        <GridToolbarDensitySelector sx={buttonActionStyle} variant='main-table-panel-borderless' />
        <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
        <ButtonExport
          disabled={disabled || isOnImportActivityProgress || isSyncinCubeActivityProgress}
          onClick={handleExportCLick}
        />
        <RoleBasedAccessProvider
          allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
        >
          <SystemStatusBasedAccessProvider>
            {isConnectedToDataSource === false && (
              <>
                <ButtonImport
                  disabled={disabled || isOnImportActivityProgress || isSyncinCubeActivityProgress}
                  onClick={onImport}
                />
                <ButtonDownloadTemplate
                  disabled={disabled || isOnImportActivityProgress || isSyncinCubeActivityProgress}
                  onClick={onButtonTemplateClick}
                />
              </>
            )}
          </SystemStatusBasedAccessProvider>
        </RoleBasedAccessProvider>
        {!viewOnly && (
          <RoleBasedAccessProvider
            allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
          >
            <SystemStatusBasedAccessProvider>
              {showSyncButton &&
                isConnectedToDataSource === false &&
                !isOnImportActivityProgress &&
                !isSyncinCubeActivityProgress && (
                  <Stack direction='row' spacing={2} ml={4}>
                    <Tooltip
                      title={`Ensure synchronization among ${totalDependentCubes} cubes linked to this data. Click the button to sync.`}
                      arrow
                    >
                      <ButtonLoading onClick={onSync} startIcon={<SyncAltOutlinedIcon />} variant='main-table-panel'>
                        Syncronize
                      </ButtonLoading>
                    </Tooltip>
                  </Stack>
                )}
              <ButtonTableOptions
                onClick={onOptionClick}
                menuOptionsId={tableMenuOptionsId}
                disabled={isOnImportActivityProgress}
              />
            </SystemStatusBasedAccessProvider>
          </RoleBasedAccessProvider>
        )}
        {myViewId && (
          <>
            <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5, mr: 2 }} />
            <ControllerNavigation
              onPrefetch={handleOnDashboardNavigatePrefetch}
              enableNavigations={controllerNaviations}
              tooltips={navigationControllerTooltips}
              onNavigate={handleOnDashboardNavigate}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
