import qs from 'query-string';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ButtonLoading from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Alert } from '@mui/material';
import { useLogout } from 'services/v1/Auth';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { inflate } from 'utils/compressor';
import { PATH_CONSTANT } from 'constant/PathConstant';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  minHeight: 270,
  bgcolor: 'background.paper',
  borderRadius: 1,
  px: 3,
  py: 2,
};

export interface ModalRecordArchiveProps {
  visible: boolean;
  onClose: () => void;
}

export function ModalRecordArchiveFound(props: ModalRecordArchiveProps) {
  const { visible = false, onClose } = props;
  const { subjectId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const archRecordCompressed = searchParams.get('arcRecord');
  const location = useLocation();
  const existingSearchParams = qs.parse(location.search);

  const archivedRecord: {
    subjectDisplayName: string;
    recordText: string;
    recordId: string;
  } | null = archRecordCompressed ? inflate(archRecordCompressed) : null;

  const { isLoading } = useLogout();

  const handleLogout = () => {
    if (subjectId && archivedRecord?.recordId) {
      navigate(
        `${PATH_CONSTANT.TENANT.KMS.DATA_INPUT_DEDICATED_PAGE_EDIT.replace(':subjectId', subjectId).replace(
          ':recordId',
          archivedRecord?.recordId
        )}?${qs.stringify({
          ...existingSearchParams,
          action: 'RESTORE_RECORD',
        })}`
      );
    }
  };
  return (
    <div>
      <Modal open={visible}>
        <Stack direction='column' sx={ModalContentStyle}>
          <Stack justifyContent='space-between' direction='row' alignItems='center'>
            <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
              Archived Record Detected
            </Typography>
          </Stack>
          <Divider sx={{ mb: 1 }} />
          <Stack sx={{ width: '100%', mt: 3 }}>
            <Alert severity='info'>
              {`We noticed that a ${archivedRecord?.subjectDisplayName} record  with ID "${archivedRecord?.recordText}" was previously deleted. To maintain data consistency, we recommend
              restoring and modifying the existing record. However, if you still wish to create a new record, you can do
              so by clicking the “Create New Record” button.`}
            </Alert>
          </Stack>
          <Stack sx={{ mt: 4, mb: 2, gap: 2, flexDirection: 'row' }}>
            <ButtonLoading
              type='submit'
              fullWidth
              onClick={onClose}
              loading={isLoading}
              loadingPosition='start'
              variant='main-table-panel-border'
              sx={{
                width: '50%',
                textTransform: 'none',
              }}
            >
              Create New Record
            </ButtonLoading>
            <ButtonLoading
              type='submit'
              fullWidth
              onClick={handleLogout}
              loading={isLoading}
              loadingPosition='start'
              variant='main-table-panel'
              sx={{
                width: '50%',
                textTransform: 'none',
              }}
            >
              Restore and Modify
            </ButtonLoading>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}
