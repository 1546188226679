import { useMemo, useRef } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonLoading from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Formik, FormikProps } from 'formik';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { CubeDefinitionRowItem } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import {
  createCalculationRuleParametersValidationSchema,
  createCubeFunctionRuleParametersValidationSchema,
} from 'config/FormValidaton/AroCube/CubeDefinitionSettingPanelValidationSchema';
import { RULES_CONSTANT } from 'constant/RuleConstant';

const ModalContentStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  height: 340,
  overflow: 'auto',
  borderRadius: 1,
  py: 2,
};

const textInputStyles = {
  width: '100%',
  '& .MuiOutlinedInput': {
    padding: '1px 2px',
  },
};

const closeIconStyle = { color: '#98A2AE', cursor: 'pointer' };

export interface ModalCubeDefinitionParamsProps<T = CubeDefinitionRowItem> {
  visible?: boolean;
  onClose?: () => void;
  cubeDefinitionItem: CubeDefinitionRowItem | null;
  onSubmitParameter?: (parameterValue: T) => void;
  referenceCodes?: string[];
  fieldName: 'ruleTypeParams' | 'consolidateRuleParams' | null;
}

type FormValues = {
  parameter: string;
};

const INPUT_TEXT_LABEL = {
  [RULES_CONSTANT.CUBE.RULE_CUBE_CALC_ATTRIBUTE]: {
    placeholder: 'Define rule',
    label: 'Calculation Rule Parameter',
  },
  [RULES_CONSTANT.CUBE.RULE_CUBE_FUNCTION]: {
    placeholder: 'Define Special Function',
    label: 'Special Function Parameter',
  },
};

export function ModalCubeDefinitionParameter(props: ModalCubeDefinitionParamsProps) {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const { onClose, visible = false, onSubmitParameter, referenceCodes, cubeDefinitionItem, fieldName } = props;

  const isConsolidate = fieldName === 'consolidateRuleParams';

  const handleOnCancel = () => {
    handleOnCloseModal?.();
  };

  const handleOnCloseModal = () => {
    onClose?.();
  };

  const handleOnSave = (data: FormValues) => {
    if (fieldName) {
      props.cubeDefinitionItem &&
        onSubmitParameter &&
        onSubmitParameter({
          ...props.cubeDefinitionItem,
          [fieldName]: data.parameter,
        });
      handleOnCloseModal();
    }
  };

  const validationSchema = useMemo(() => {
    if (isConsolidate) {
      return createCalculationRuleParametersValidationSchema(referenceCodes || [], isConsolidate);
    }

    // @ts-ignore
    if (cubeDefinitionItem?.ruleType.code === RULES_CONSTANT.CUBE.RULE_CUBE_CALC_ATTRIBUTE) {
      return createCalculationRuleParametersValidationSchema(referenceCodes || [], isConsolidate);
    }
    return createCubeFunctionRuleParametersValidationSchema(referenceCodes || []);
  }, [referenceCodes, cubeDefinitionItem, isConsolidate]);

  const formikInitialValues = useMemo(() => {
    if (!fieldName)
      return {
        parameter: '',
      };
    return {
      parameter: props.cubeDefinitionItem?.[fieldName] || '',
    };
  }, [props.cubeDefinitionItem, fieldName]);

  return (
    <div>
      <Modal open={visible} onClose={handleOnCloseModal}>
        <Stack direction='column' sx={ModalContentStyle} justifyContent='space-between'>
          <Formik
            innerRef={formikRef}
            initialValues={formikInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSave}
          >
            {({ handleSubmit, setFieldValue, handleBlur, touched, errors, values }) => {
              return (
                <>
                  <Stack>
                    <Stack px={2} justifyContent='space-between' direction='row' alignItems='center'>
                      <Typography variant='body1' component='h2' fontWeight='bold' sx={{ color: '#3B4797' }}>
                        {isConsolidate
                          ? `Consolidate rule for ${props?.cubeDefinitionItem?.attribute?.label}`
                          : `Parameter for ${props?.cubeDefinitionItem?.attribute?.label}`}
                      </Typography>
                      <IconButton onClick={handleOnCloseModal} component='label'>
                        <HighlightOffRoundedIcon sx={closeIconStyle} />
                      </IconButton>
                    </Stack>
                    {!isConsolidate && (
                      <Stack
                        sx={{
                          bgcolor: '#FBFBFB',
                          py: 3,
                          px: 2,
                          borderTop: '1px solid #E3EBF6',
                        }}
                      >
                        <Typography variant='body2' fontWeight='bold' component='h2'>
                          {props.cubeDefinitionItem?.ruleType?.label}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>

                  <Stack px={15}>
                    <TextField
                      value={values.parameter}
                      name='parameter'
                      onChange={(e) => setFieldValue('parameter', e.target.value.toUpperCase())}
                      onBlur={handleBlur}
                      // @ts-ignore
                      label={INPUT_TEXT_LABEL[props.cubeDefinitionItem?.ruleType?.code]?.label || 'Calculation Rule'}
                      sx={textInputStyles}
                      size='small'
                      // @ts-ignore
                      placeholder={
                        // @ts-ignore
                        INPUT_TEXT_LABEL[props.cubeDefinitionItem?.ruleType?.code]?.placeholder ||
                        'Input calculation rule '
                      }
                      type='text'
                      error={touched.parameter && Boolean(errors.parameter)}
                      helperText={touched.parameter && errors.parameter}
                    />
                  </Stack>

                  <Stack px={2}>
                    <Divider sx={{ mb: 1, mt: 2 }} />
                    <Stack direction='row' justifyContent='flex-end' spacing={2} alignItems='center' sx={{ py: 1 }}>
                      <Button variant='main-table-panel-border' onClick={handleOnCancel}>
                        Cancel
                      </Button>
                      <ButtonLoading variant='main-table-panel' onClick={() => handleSubmit()}>
                        Save Parameter
                      </ButtonLoading>
                    </Stack>
                  </Stack>
                </>
              );
            }}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
}
