import { useEffect, useImperativeHandle, useMemo, useRef, useState, forwardRef } from 'react';
import { toast } from 'react-toastify';
import styled from '@mui/system/styled';
import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import MultipleStopOutlinedIcon from '@mui/icons-material/MultipleStopOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ButtonLoading from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, FormikProps } from 'formik';
import { DropdownItem } from 'types/api/SystemTenant/AROKMS/TableDefinitionTypes';
import { CubeDefinitionDetails } from 'types/api/SystemTenant/AROCube/CubeBuilderTypes';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import {
  useGetCubeIngestionStatus,
  useGetCubeMemberDropdownByCube,
  useGetCubeXMemberDropdownByCube,
  useGetLayerListDropdownByCube,
  useGetCubeBaselineConnectionOptions,
  usePullBaselineDataFromCubeConnection,
  useGetCubeCurrentTimePeriodDate,
} from 'services/v1/Tenant/AROCube/CubeDataEntryService';
import { Tooltip } from '@mui/material';
import { flattenTreeData, renderOptionsKtree, getListBoxPropsAutoScrolItem } from 'utils/Object';
import { useGetCubeDefinitionDetails } from 'services/v1/SystemTenant/AROCube/CubeBuilderService';
import { InsightMenuOptions } from './InsightViewOptions';
import { ButtonRetryWithPopper } from './ButtonRetryWithPopover';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import {
  WorkFlowDrillNavigationDirection,
  getCurrentMyViewIndex,
  getNextDrillDownURL,
  getNextMyViewItem,
  getPreviousMyViewItem,
  getWorkFlowDrillView,
} from 'utils/MyViewUtils';
import PullBaseLineValueMenu from './PullBaselineValueMenu';
import { ModalConfirmationComponent } from 'components/ModalComponent/ModalConfirmationComponent';
import { ButtonCubeDrillThru, FormCubeDrillThruParameter } from './CubeDrillThruDataButton';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export interface OptionItem {
  value: string | number;
  label: string;
}

const autoCompleteStyle = {
  width: 230,
  '& .MuiAutocomplete-popper': {
    backgroundColor: 'red !imoprtant',
    fontSize: '10px',
  },
};

const textInputStyle = {};

const INGESTION_DATA_ON_PROGRESS_STATUS = ['RUNNING', 'PENDING', 'WAITING', 'CREATED'];

export type CubeDataEntryPanelOptionValues = {
  subject: DropdownItem | null;
  cube: DropdownItem | null;
  layer: DropdownItem | null;
};

export type CubeDataEntryShowDataDetails = {
  eventSubjectId: string | null;
  eventSubjectName: string | null;
  cubeMember: string | null;
  timePeriod: string | null;
};

interface CubeDataEntryPanelProps {
  disabled?: boolean;
  cubeId: string;
  ref?: any;
  onOptionChange?: (data: CubeDataEntryPanelOptionValues) => void;
  onButtonSaveClick?: () => void;
  onButtonSycnClick?: () => void;
  onShowCubeView?: () => void;
  onShowDataDetails: (data: CubeDataEntryShowDataDetails) => void;
  onInsightMenuOptionClick?: (type: AutoCompleteItem) => void;
  onRefresh?: () => void;
  loading?: boolean;
  initialValues?: CubeDefinitionDetails;
  edit?: boolean;
  showSaveButton?: boolean;
  showSyncButton?: boolean;
  setIsLoadingOptionsData?: (isLoading: boolean) => void;
}

const formikInitialValues = {
  subject: null,
  cube: null,
  layer: null,
};

const BlinkingIcon = styled(SettingsEthernetOutlinedIcon)`
  animation: blink-slide 2s linear infinite;

  @keyframes blink-slide {
    0%,
    100% {
      color: #999999;
    }
    25% {
      color: #cccccc;
    }
    50% {
      color: #999999;
    }
    75% {
      color: #cccccc;
    }
  }
`;

export const CubeDataEntrySettingPanel = forwardRef((props: CubeDataEntryPanelProps, ref) => {
  const {
    disabled: disableProps,
    edit = false,
    onOptionChange,
    showSaveButton,
    onButtonSaveClick,
    onShowCubeView,
    onShowDataDetails,
    onRefresh,
    loading,
    setIsLoadingOptionsData,
    onInsightMenuOptionClick = () => {},
    cubeId,
  } = props;

  const [params] = useSearchParams();
  const myViewId = params.get('myViewId');
  const workflowId = params.get('workflowId');
  const memberId = params.get('memberId');
  const [isIngestingData, setIngestingData] = useState<boolean>(false);
  const { data: cubeDetails } = useGetCubeDefinitionDetails(cubeId);
  const { role } = useSelector((state: RootState) => state.auth);
  const [baseLineMenuEl, setBaseLineMenuEl] = useState<HTMLElement | null>(null);
  const [selectedPullBaselinOption, setSelectedPullBaselineOption] = useState<AutoCompleteItem | null>(null);

  const [optionValue, setOptionValue] = useState<{
    subject: DropdownItem | null;
    cube: DropdownItem | null;
    layer: DropdownItem | null;
    member: DropdownItem | null;
    xMember: DropdownItem | null;
  }>({
    subject: null,
    cube: null,
    layer: null,
    member: null,
    xMember: null,
  });
  const formikRef = useRef<FormikProps<CubeDataEntryPanelOptionValues>>(null);

  const navigate = useNavigate();
  const { data: cubeCurrentDate } = useGetCubeCurrentTimePeriodDate(cubeId);
  const { data: layerDropdown, isLoading: isFetchingLayer } = useGetLayerListDropdownByCube(cubeId);
  const { data: cubeBaselineConnectionOptions } = useGetCubeBaselineConnectionOptions(
    cubeId,
    optionValue.layer?.value,
    optionValue.member?.value
  );
  const { data: memberDropdown, isLoading: isFetchingMember } = useGetCubeMemberDropdownByCube(cubeId);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);
  const { mutate: pullBaselineData } = usePullBaselineDataFromCubeConnection();
  const {
    data: cubeIngestionStatus,
    refetch: refetchIngestionStatus,
    isRefetching: isRefreshing,
  } = useGetCubeIngestionStatus(cubeId, optionValue.layer?.value, {
    refetchInterval: isIngestingData ? 5000 : false,
  });
  const { data: memberXDropdown } = useGetCubeXMemberDropdownByCube(cubeId);

  const { data: cubeDefinitionDetails } = useGetCubeDefinitionDetails(cubeId);

  const disabled = disableProps;
  const memberOptions = useMemo(() => {
    if (memberDropdown) {
      return flattenTreeData(memberDropdown);
    }
    return [];
  }, [memberDropdown]);

  const memberXOptions = useMemo(() => {
    if (memberXDropdown) {
      return flattenTreeData(memberXDropdown);
    }
    return [];
  }, [memberXDropdown]);

  const lowestMemberLevel = useMemo(() => {
    // @ts-ignore
    return memberOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberOptions]);

  const lowestMemberXLevel = useMemo(() => {
    // @ts-ignore
    return memberXOptions.reduce((acc, item) => {
      if (item.level > acc) {
        return item.level;
      } else {
        return acc;
      }
    }, 0);
  }, [memberXOptions]);

  const currentMyViewItem = useMemo(() => {
    if (!myViewId) return null;
    if (myViewDetails?.data) return myViewDetails.data;
    return allMyViewsData?.data.myViewItems.find((item) => item.viewId === myViewId) ?? null;
  }, [allMyViewsData?.data, myViewId, myViewDetails?.data]);

  const nextDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workflowId, WorkFlowDrillNavigationDirection.DOWN);
  }, [currentMyViewItem, workflowId]);
  const previousDrilDownWorkFlow = useMemo(() => {
    return getWorkFlowDrillView(currentMyViewItem, workflowId, WorkFlowDrillNavigationDirection.UP);
  }, [currentMyViewItem, workflowId]);

  const insightViewList = useMemo(() => {
    if (allMyViewsData?.data) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;

      if (selectedDashboard) {
        return selectedDashboard.myViews;
      }
    }

    return [];
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const currentMyViewIndex = useMemo(() => {
    if (myViewId == null) return 0;
    return getCurrentMyViewIndex(insightViewList, myViewId);
  }, [myViewId, insightViewList]);

  const nextViewItem = useMemo(() => {
    return getNextMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const previousViewItem = useMemo(() => {
    return getPreviousMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  useEffect(() => {
    if (cubeIngestionStatus?.data) {
      const isIngesting = INGESTION_DATA_ON_PROGRESS_STATUS.includes(cubeIngestionStatus?.data.ingestionStatus);
      setIngestingData(isIngesting);
      return;
    }
    setIngestingData(false);
  }, [cubeIngestionStatus?.data]);

  useEffect(() => {
    onOptionChange?.(optionValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionValue]);

  // auto select layer
  useEffect(() => {
    if (layerDropdown?.data && layerDropdown?.data.length > 0) {
      const layer = layerDropdown?.data[0];
      const selectedLayer: DropdownItem = {
        id: layer.value,
        value: layer?.value?.toString() || '',
        label: layer.label,
      };
      setOptionValue((prev) => ({ ...prev, layer: selectedLayer }));
    }
  }, [layerDropdown?.data]);

  // auto select member
  useEffect(() => {
    if (memberOptions && memberOptions?.length > 0) {
      const initialMember = memberOptions.find((item: DropdownItem) => item.value === memberId);
      if (initialMember) {
        const selectedMember: DropdownItem = {
          id: initialMember.value,
          value: initialMember?.value?.toString() || '',
          label: initialMember.label,
        };
        setOptionValue((prev) => ({ ...prev, member: selectedMember }));
      } else {
        const member = memberOptions?.[0];
        const selectedMember: DropdownItem = {
          id: member.value,
          value: member?.value?.toString() || '',
          label: member.label,
        };
        setOptionValue((prev) => ({ ...prev, member: selectedMember }));
      }
    }
  }, [memberOptions, memberId]);

  useEffect(() => {
    if (memberXOptions && memberXOptions?.length > 0) {
      const memberX = memberXOptions?.[0];
      const selectedMemberX: DropdownItem = {
        id: memberX.value,
        value: memberX?.value?.toString() || '',
        label: memberX.label,
      };
      setOptionValue((prev) => ({ ...prev, xMember: selectedMemberX }));
    }
  }, [memberXOptions]);

  useEffect(() => {
    if (cubeDetails?.data) {
      setOptionValue((prevState) => ({
        ...prevState,
        subject: {
          id: cubeDetails?.data?.subject?.id,
          value: cubeDetails?.data?.subject?.id?.toString() || '',
          label: cubeDetails?.data?.subject?.displayName,
        },
        cube: {
          id: cubeDetails?.data?.id,
          value: cubeDetails?.data?.id?.toString() || '',
          label: cubeDetails?.data?.cubeName,
        },
      }));
      return;
    }
    setOptionValue((prevState) => ({
      ...prevState,
      layer: null,
      member: null,
      xMember: null,
    }));
  }, [cubeDetails?.data, cubeId]);

  useImperativeHandle(ref, () => ({
    isLoadingOptionsData: isFetchingLayer || isFetchingMember,
  }));

  useEffect(() => {
    setIsLoadingOptionsData && setIsLoadingOptionsData(isFetchingLayer || isFetchingMember);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingLayer, isFetchingMember]);

  const handleOnButtonSaveClick = () => {
    onButtonSaveClick && onButtonSaveClick();
  };

  const handleOnRefresh = () => {
    refetchIngestionStatus();
    onRefresh && onRefresh();
  };

  const handleOnInsightMenuOptionClick = (type: AutoCompleteItem) => {
    onInsightMenuOptionClick?.(type);
  };
  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    if (direction === ControllerNavigationDirection.UP) {
      const previousDrillDownURL = getNextDrillDownURL(currentMyViewItem, previousDrilDownWorkFlow, {
        memberId: optionValue.member?.value,
        ref: PAGE_REF_NAVIGATION.TOP,
      });
      previousDrillDownURL && navigate(previousDrillDownURL);
    }
    if (direction === ControllerNavigationDirection.DOWN && nextDrilDownWorkFlow) {
      const nextDrillDownURL = getNextDrillDownURL(currentMyViewItem, nextDrilDownWorkFlow, {
        memberId: optionValue.member?.value,
        ref: PAGE_REF_NAVIGATION.BOTTOM,
      });
      nextDrillDownURL && navigate(nextDrillDownURL);
    }
    if (direction === ControllerNavigationDirection.RIGHT && nextViewItem) {
      const nextMyViewItemURL = getQuickAccessNavigateURL(nextViewItem, undefined, PAGE_REF_NAVIGATION.RIGHT, role);
      nextMyViewItemURL && navigate(nextMyViewItemURL);
    }
    if (direction === ControllerNavigationDirection.LEFT && previousViewItem) {
      const previousMyViewItemURL = getQuickAccessNavigateURL(
        previousViewItem,
        undefined,
        PAGE_REF_NAVIGATION.LEFT,
        role
      );
      previousMyViewItemURL && navigate(previousMyViewItemURL);
    }
  };

  const handleOnShowBaselineMenu = (event: React.MouseEvent<HTMLElement>) => {
    setBaseLineMenuEl(event.currentTarget);
  };

  const handleOnPullBaselineData = () => {
    if (optionValue.layer?.value && optionValue.member?.value && selectedPullBaselinOption?.value && cubeId) {
      pullBaselineData(
        {
          cubeId: cubeId,
          layerId: optionValue.layer?.value,
          memberId: optionValue.member?.value,
          targetCubePullId: selectedPullBaselinOption?.value.toString(),
        },
        {
          onSuccess: () => {
            toast.success('Pulling baseline data is successful!');
            setSelectedPullBaselineOption(null);
          },
        }
      );
    }
  };

  const handleOnButtonDrillThruClick = (data: FormCubeDrillThruParameter) => {
    onShowDataDetails &&
      onShowDataDetails({
        eventSubjectId: (data.eventSubject?.value as string) || null,
        eventSubjectName: (data.eventSubject?.label as string) || null,
        cubeMember: data.member?.label || null,
        timePeriod: data.timePeriod?.label || null,
      });
  };

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {
      [ControllerNavigationDirection.UP]: previousDrilDownWorkFlow?.componentName
        ? `Show ${previousDrilDownWorkFlow.componentName}`
        : 'Show Insight',
      [ControllerNavigationDirection.DOWN]: nextDrilDownWorkFlow?.componentName
        ? `Show ${nextDrilDownWorkFlow?.componentName}`
        : 'Show Data',
    };
    if (insightViewList.length > 1) {
      if (currentMyViewIndex > -1) {
        if (currentMyViewIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${insightViewList[currentMyViewIndex - 1].title}`;
        }
        if (currentMyViewIndex < insightViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${
            insightViewList[currentMyViewIndex + 1].title
          }`;
        }
      }
    }
    return defaultToolTips;
  }, [previousDrilDownWorkFlow, nextDrilDownWorkFlow, currentMyViewIndex, insightViewList]);

  const enableControllerNavigations = useMemo(() => {
    const controller = [];
    if (nextDrilDownWorkFlow && previousDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.UP);
      controller.push(ControllerNavigationDirection.DOWN);
    }
    if (previousDrilDownWorkFlow && !nextDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.UP);
    }
    if (!previousDrilDownWorkFlow && nextDrilDownWorkFlow) {
      controller.push(ControllerNavigationDirection.DOWN);
    }
    if (insightViewList?.length > 1) {
      if (currentMyViewIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentMyViewIndex < insightViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }
    return controller;
  }, [nextDrilDownWorkFlow, previousDrilDownWorkFlow, currentMyViewIndex, insightViewList]);

  const modalConfimationPullDataMessage = useMemo(() => {
    if (!selectedPullBaselinOption) return '';
    const attributes = cubeBaselineConnectionOptions?.data.connectionAttributes[selectedPullBaselinOption?.value];

    const attributesRender = attributes?.map((attribute) => `<li>${attribute}</li>`).join('');
    return `${cubeDefinitionDetails?.data.cubeName} will pull data for the following attribute(s) from the ${selectedPullBaselinOption?.label}: <ul>${attributesRender}</ul> Are you sure you want to proceed?`;
  }, [cubeDefinitionDetails?.data.cubeName, cubeBaselineConnectionOptions?.data, selectedPullBaselinOption]);

  const drillThruInitialValues = useMemo(() => {
    return {
      member: optionValue.member,
      timePeriod: cubeCurrentDate?.data?.currentPeriod
        ? {
            label: cubeCurrentDate?.data.currentPeriod,
            value: cubeCurrentDate?.data.currentPeriod,
          }
        : null,
    };
  }, [cubeCurrentDate?.data.currentPeriod, optionValue.member]);

  const isBaseMember = useMemo(() => {
    const selectedMember = memberOptions.find((item: any) => item.id === optionValue.member?.id);
    return selectedMember?.level === 0;
  }, [optionValue.member, memberOptions]);

  return (
    <Stack direction='column' sx={{ backgroundColor: '#fff' }}>
      <Formik onSubmit={() => {}} innerRef={formikRef} initialValues={formikInitialValues}>
        {({ errors, touched, handleBlur, handleChange, values, setFieldValue, handleSubmit }) => {
          return (
            <>
              <Stack
                direction='row'
                justifyContent='space-between'
                spacing={0}
                sx={{ backgroundColor: '#fff', py: 1, px: 2 }}
              >
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
                    {cubeCurrentDate?.data.currentPeriodDate &&
                      ` Current Period: ${cubeCurrentDate.data.currentPeriodDate}`}
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  {!showSaveButton && (
                    <InsightMenuOptions
                      disabled={isIngestingData || disabled || cubeIngestionStatus?.data?.ingestionStatus === 'FAILED'}
                      onOptionClick={handleOnInsightMenuOptionClick}
                      cubeId={cubeId ?? ''}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                gap={2}
                sx={{ bgcolor: '#FBFBFB', py: 1, px: 2, border: '1px solid #E3EBF6' }}
              >
                <Stack direction='row' justifyContent='space-between' width='100%'>
                  <Stack direction='row' spacing={5} alignItems='center'>
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({ ...prev, member: value }));
                      }}
                      clearIcon={null}
                      sx={{
                        width: 300,
                      }}
                      size='small'
                      value={optionValue.member}
                      disabled={disabled || !optionValue.cube || !optionValue.layer || isFetchingMember}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={memberOptions || []}
                      renderOption={(props, option, state) =>
                        renderOptionsKtree(props, option, state, lowestMemberLevel)
                      }
                      ListboxProps={{
                        ...getListBoxPropsAutoScrolItem(memberOptions, optionValue.member),
                      }}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={
                              isFetchingMember ? 'Loading...' : cubeDefinitionDetails?.data?.member?.label || 'Member'
                            }
                            onBlur={handleBlur}
                            name='member'
                          />
                        </Stack>
                      )}
                    />
                    {cubeDefinitionDetails?.data.subjectX && (
                      <Autocomplete
                        // @ts-ignore
                        onChange={(event, value) => {
                          setOptionValue((prev) => ({ ...prev, xMember: value }));
                        }}
                        clearIcon={null}
                        size='small'
                        sx={{
                          width: 300,
                        }}
                        value={optionValue.xMember}
                        disabled={disabled || !optionValue.cube || !optionValue.layer || isFetchingMember}
                        getOptionLabel={(option: DropdownItem) => option.label}
                        options={memberXOptions || []}
                        renderOption={(props, option, state) =>
                          renderOptionsKtree(props, option, state, lowestMemberXLevel)
                        }
                        ListboxProps={{
                          ...getListBoxPropsAutoScrolItem(memberXOptions, optionValue.xMember),
                        }}
                        renderInput={(params) => (
                          <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                            <TextField
                              {...params}
                              sx={textInputStyle}
                              label={
                                isFetchingMember
                                  ? 'Loading...'
                                  : cubeDefinitionDetails?.data?.subjectX?.label || 'Member'
                              }
                              onBlur={handleBlur}
                              name='member'
                            />
                          </Stack>
                        )}
                      />
                    )}
                    <Autocomplete
                      // @ts-ignore
                      onChange={(event, value) => {
                        setOptionValue((prev) => ({ ...prev, layer: value }));
                      }}
                      clearIcon={null}
                      size='small'
                      value={optionValue.layer}
                      disabled={disabled || !optionValue.cube || isFetchingLayer}
                      getOptionLabel={(option: DropdownItem) => option.label}
                      options={layerDropdown?.data || []}
                      renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > span': { fontSize: '14px', mr: 1, flexShrink: 0 } }} {...props}>
                          <span>{option.label}</span>
                        </Box>
                      )}
                      sx={autoCompleteStyle}
                      renderInput={(params) => (
                        <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                          <TextField
                            {...params}
                            sx={textInputStyle}
                            label={isFetchingLayer ? 'Loading...' : 'Version'}
                            onBlur={handleBlur}
                            name='layer'
                          />
                        </Stack>
                      )}
                    />
                  </Stack>
                  <Stack direction='row' gap={2} width={230} alignItems='center' justifyContent='flex-end'>
                    {showSaveButton && (
                      <Tooltip title="Save the changes you've made to this cube." arrow>
                        <ButtonLoading
                          onClick={handleOnButtonSaveClick}
                          loading={loading}
                          startIcon={edit ? null : <SaveOutlinedIcon />}
                          variant={edit ? 'main-table-panel' : 'secondary-table-panel'}
                        >
                          Save
                        </ButtonLoading>
                      </Tooltip>
                    )}
                    {isBaseMember && (
                      <ButtonCubeDrillThru
                        initialValue={drillThruInitialValues}
                        cubeId={cubeId}
                        onDisplayData={handleOnButtonDrillThruClick}
                      />
                    )}
                    {cubeBaselineConnectionOptions?.data &&
                      cubeBaselineConnectionOptions.data.connections.length > 0 && (
                        <Tooltip title='Pull Baseline Data' arrow>
                          <IconButton aria-label='delete' size='small' onClick={handleOnShowBaselineMenu}>
                            <MultipleStopOutlinedIcon
                              sx={{
                                color: '#42BB93',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                    {!showSaveButton && cubeIngestionStatus?.data && (
                      <>
                        {cubeIngestionStatus?.data?.ingestionStatus === 'SUCCESS' && (
                          <Tooltip title='Dimensional View' arrow>
                            <IconButton aria-label='delete' size='small' onClick={onShowCubeView}>
                              <ViewInArOutlinedIcon
                                sx={{
                                  color: '#42BB93',
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!disabled && isIngestingData && (
                          <Tooltip title='Ingesting data now... It will be ready in 1-2 minutes' arrow>
                            <IconButton aria-label='delete' size='small'>
                              <BlinkingIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!disabled && cubeIngestionStatus?.data?.ingestionStatus === 'FAILED' && (
                          <ButtonRetryWithPopper
                            errorMessage={cubeIngestionStatus?.data?.ingestionMessage}
                            cubeId={optionValue.cube?.value || ''}
                            layerId={optionValue.layer?.value || ''}
                          />
                        )}

                        <Tooltip title='Refresh' arrow onClick={handleOnRefresh}>
                          <IconButton aria-label='delete' size='small'>
                            {isRefreshing && (
                              <CircularProgress
                                size={18}
                                sx={{
                                  color: '#42BB93',
                                }}
                              />
                            )}
                            {!isRefreshing && (
                              <LoopOutlinedIcon
                                sx={{
                                  color: '#42BB93',
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Stack>
                </Stack>
                {enableControllerNavigations.length > 0 && (
                  <ControllerNavigation
                    enableNavigations={enableControllerNavigations}
                    tooltips={navigationControllerTooltips}
                    onNavigate={handleOnDashboardNavigate}
                  />
                )}
              </Stack>
            </>
          );
        }}
      </Formik>
      <ModalConfirmationComponent
        visible={Boolean(selectedPullBaselinOption?.value)}
        title='Pull Baseline Data'
        width={600}
        onApprove={handleOnPullBaselineData}
        onCancel={() => setSelectedPullBaselineOption(null)}
        onClose={() => setSelectedPullBaselineOption(null)}
        dangerouslySetInnerHTML={{
          __html: modalConfimationPullDataMessage,
        }}
        messageType='info'
        labelButtonApprove='Proceed'
      />
      <PullBaseLineValueMenu
        anchorEl={baseLineMenuEl}
        onClick={(selectedValue: AutoCompleteItem) => {
          setSelectedPullBaselineOption(selectedValue);
        }}
        onClose={() => {
          setBaseLineMenuEl(null);
        }}
        cubeId={cubeId}
        cubeMemberId={optionValue.member?.value}
        cubeLayerId={optionValue.layer?.value}
      />
    </Stack>
  );
});
