import * as React from 'react';
import { Paper, Stack, SxProps, Backdrop, IconButton, Tooltip, Chip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import ModalConfigurationPage from 'pages/Tenant/ConfigurationPage';
import { BreadCumbItemType, NavBarStatusBar } from 'components/LayoutComponent/VerticalLayout/NavBarStatusBar';
import { Navigation } from './NavBar';
import { DashboardVerticalNavigationAppBar } from './UserMenuOptions';
import { ModalChangeLanguageComponent } from 'components/ModalComponent/ModalChangeLanguageComponent';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { useIsFetching } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { ProgressTopBarComponent } from '../../ProgressTopBarComponent';
import { ModalChangeRole } from '../../../pages/Auth/ModalChangeRole';
import { ModalSessionExpired } from 'pages/Auth/ModalSessionExpired';
import { SESSION_STATUS_CONSTANT } from 'constant/SessionStatusConstant';
import { AxiosDefaultErrorEntity } from 'types';
import { ErrorBoxComponent } from '../../Error/ErrorBoxComponent';
import { BackToListButton } from 'components/ButtonComponent/ButtonBackToList';
import { QuickAccessBox } from './QuickAccess';
import { toggleTenantNavigation } from 'store/reducer/uiReducer';
import { BreadcrumbItem } from 'types/api/Tenant/HomeDashboardTypes';
import { useNavigate } from 'react-router-dom';
import { PATH_CONSTANT } from 'constant/PathConstant';
import { ROLES } from 'constant/PermissonConstant';

export type CustomIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

const chipAdminLabelStyle = {
  fontSize: '0.8rem',
  padding: 0,
  height: 15,
  py: 0.5,
  px: 0.5,
  width: 'auto',
};

export type SelectedMenuItems = {
  subject: AutoCompleteItem | null;
  ktree: AutoCompleteItem | null;
  event: AutoCompleteItem | null;
  cube: AutoCompleteItem | null;
  'subject-procedure': AutoCompleteItem | null;
};

type TenantHomeDashboardNavigationProps = {
  children: React.ReactNode | JSX.Element;
  containerSx?: SxProps;
  rootContainerSx?: SxProps;
  error?: AxiosDefaultErrorEntity | null;
  onBackButtonClick?: () => void;
  backButtonTitle?: string;
  breadCrumbs?: BreadCumbItemType[];
};

export const NAV_ITEM_TYPE = {
  SUBJECT: 'subject',
  KTREE: 'ktree',
  EVENT: 'event',
  CUBE: 'cube',
  STANDARD_VIEW: 'view',
  SUBJECT_PROCEDURE: 'subject-procedure',
  INSIGHT_VIEW: 'insight',
  DASHBOARD: 'dashboard',
  ORGANIZATION: 'organization',
  MASTER_TABLE_DISPLAY: 'master-table-display',
  MASTER_TABLE: 'master-table',
};

export function generateBreadCumbItemsV2(data: BreadcrumbItem[]): BreadCumbItemType[] {
  return (
    data?.map((item) => {
      const breadItem: BreadCumbItemType = {
        id: item.id,
        label: item.name,
        type: item.componentType,
      };
      return breadItem;
    }) || []
  );
}

function ImageLogoComponent() {
  const navigate = useNavigate();
  const { selectedTenant } = useSelector((state: RootState) => state.auth);
  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={1}
      onClick={() => navigate(PATH_CONSTANT.TENANT.HOME)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <img
        src='/images/main-logo.svg'
        alt='logo'
        style={{
          width: '120px',
          height: 'auto',
        }}
      />
      <Stack direction='row' gap={1}>
        <Chip
          variant='filled'
          label={selectedTenant.tenant.industryName}
          color='primary'
          size='small'
          sx={chipAdminLabelStyle}
        />
      </Stack>
    </Stack>
  );
}

export function TenantHomeDashboardNavigationBar(props: TenantHomeDashboardNavigationProps) {
  const isAnyFetching = useIsFetching();

  const [isQuickAccessOpen, setIsQuickAccessOpen] = React.useState(false);
  const { children, containerSx, rootContainerSx, error, onBackButtonClick, backButtonTitle, breadCrumbs = [] } = props;
  const authReducer = useSelector((state: RootState) => state.auth);
  const [isMenuClickable, setIsMenuClickable] = React.useState(false);
  const { showModalSelectLanguage, modalKey, isTenantNavigationOpen } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();

  const [showModalChangeRole, setShowModalChangeRole] = React.useState(false);
  const [showModalConfigurationPage, setShowModalConfigurationPage] = React.useState(false);

  const handleOnCloseModalChangeRole = () => {
    setShowModalChangeRole(false);
  };
  const handleOpenModalChangeRole = () => {
    setShowModalChangeRole(true);
  };

  const handleOpenConfigrationPage = () => {
    setShowModalConfigurationPage(true);
  };

  const handleOnToggleQuickAccess = () => {
    setIsQuickAccessOpen(!isQuickAccessOpen);
  };

  const handleOnToggleShowNavigation = () => {
    setTimeout(() => {
      setIsMenuClickable(!isTenantNavigationOpen);
    }, 600);

    dispatch(toggleTenantNavigation());
  };

  React.useEffect(() => {
    if (isTenantNavigationOpen) {
      setIsMenuClickable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', ...rootContainerSx }}>
      <ProgressTopBarComponent visible={!!isAnyFetching} />
      <CssBaseline />
      {isQuickAccessOpen && (
        <Backdrop open={isQuickAccessOpen} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
          <AppBar component='nav' sx={{ boxShadow: 'none', zIndex: 10 }} position='fixed'>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <ImageLogoComponent />

                {isTenantNavigationOpen && <Navigation clickAble />}
                {![ROLES.MANAGER, ROLES.TRAINER].includes(authReducer.role) && (
                  <Tooltip title={isTenantNavigationOpen ? 'Show less menu' : 'Show more menu'}>
                    <IconButton onClick={handleOnToggleShowNavigation}>
                      <DragIndicatorOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              <DashboardVerticalNavigationAppBar
                onChangeRoleClick={handleOpenModalChangeRole}
                onToggleQuickAccess={handleOnToggleQuickAccess}
                onConfigurationClick={handleOpenConfigrationPage}
              />
            </Toolbar>
            <NavBarStatusBar breadCumbItems={breadCrumbs} />
            <QuickAccessBox onClose={handleOnToggleQuickAccess} />
          </AppBar>
        </Backdrop>
      )}

      <AppBar
        component='nav'
        sx={{
          boxShadow: 'none',
          zIndex: 10,
        }}
        position='fixed'
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <ImageLogoComponent />

            {isTenantNavigationOpen && <Navigation clickAble={isMenuClickable} />}

            {![ROLES.MANAGER, ROLES.TRAINER].includes(authReducer.role) && (
              <Tooltip title={isTenantNavigationOpen ? 'Show less menu' : 'Show more menu'}>
                <IconButton onClick={handleOnToggleShowNavigation}>
                  <DragIndicatorOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <DashboardVerticalNavigationAppBar
            onChangeRoleClick={handleOpenModalChangeRole}
            onToggleQuickAccess={handleOnToggleQuickAccess}
            onConfigurationClick={handleOpenConfigrationPage}
          />
        </Toolbar>
        <NavBarStatusBar breadCumbItems={breadCrumbs} />
      </AppBar>
      <Toolbar />
      <Stack px={3} pt={1} mt={4} height='100%'>
        {onBackButtonClick && (
          <Stack width={300} alignItems='flex-start'>
            <BackToListButton onClick={onBackButtonClick} title={backButtonTitle} />
          </Stack>
        )}
        <Paper
          sx={{
            width: '100%',
            ...containerSx,
          }}
        >
          {!error && children}
          {error && <ErrorBoxComponent error={error} />}
        </Paper>
      </Stack>
      <ModalChangeRole visible={showModalChangeRole} onClose={handleOnCloseModalChangeRole} />
      <ModalChangeLanguageComponent visible={showModalSelectLanguage} key={modalKey} />
      <ModalSessionExpired visible={authReducer.sessionStatus === SESSION_STATUS_CONSTANT.EXPIRED} />
      <ModalConfigurationPage
        visible={showModalConfigurationPage}
        onClose={() => setShowModalConfigurationPage(false)}
      />
    </Box>
  );
}
