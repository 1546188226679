import { useEffect, useMemo, useState } from 'react';
import { useIsMutating } from 'react-query';
import Stack from '@mui/material/Stack';
import Typograhpy from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Divider from '@mui/material/Divider';
import { GridRowModesModel, GridRowsProp, GridRowModes, useGridApiContext } from '@mui/x-data-grid-pro';
import { FILE_TYPE_PARAMS } from 'constant/ImportExportConstant';
import { ButtonExport, FileType } from 'components/ButtonComponent/ButtonExport';
import { ButtonDownloadTemplate } from 'components/ButtonComponent/ButtonDownloadTemplate';
import { ButtonImport } from 'components/ButtonComponent/ButtonImport';
import { GridToolbarDensitySelector } from 'components/ButtonComponent/ButtonDensity';
import { GridToolbarFilterButton } from 'components/ButtonComponent/ButtonFilter';
import { RoleBasedAccessProvider } from 'components/RBAC';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

// Custom Icon
import { ROLES } from 'constant/PermissonConstant';
import {
  ButtonTableOptions,
  DataInputTableOptions,
  EventTableOptions,
} from 'components/ButtonComponent/ButtonTableOptions';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { SUBJECT_TYPE } from 'constant/DataInputConstant';
import { SystemStatusBasedAccessProvider } from 'components/RBAC/SystemStatusBasedAccessProvider';
import { useGetSubjectDetails } from 'services/v1/SystemTenant/AROKMS/SubjectService';
import {
  useGetCurrentImportActivity,
  useGetDisplayTableSettingPanel,
} from 'services/v1/Tenant/AROKMS/DisplayTableService';
import { DataInputImportActivityStatus } from 'types/api/Tenant/AROKMS/DisplayTableTypes';
import { generateColumnNames, handleExport } from 'utils/ExportUtils';
import { ButtonTableAnalytics, FormTableAnalyticParameter } from 'components/ButtonComponent/ButtonTableAnalytic';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetMyPersonalization, useGetMyViewDetails } from 'services/v1/Tenant/ConfigurationService';
import { getCurrentMyViewIndex, getNextMyViewItem, getPreviousMyViewItem } from 'utils/MyViewUtils';
import { ControllerNavigation, ControllerNavigationDirection } from 'pages/Tenant/AroDashboard/ControllerNavigation';
import { getQuickAccessNavigateURL } from 'components/LayoutComponent/VerticalLayout/QuickAccess';
import { PAGE_REF_NAVIGATION } from 'utils/Animation';
import { useDashboardNaviagationPrefetchHandler } from 'hooks/useDashboardNavigationPrefetchHandler';
import { Alert, Autocomplete, TextField } from '@mui/material';
import { AutoCompleteItem } from 'types/api/Common/AutoCompleteTypes';
import { flattenTreeData, getListBoxPropsAutoScrolItem, renderOptionsKtree } from 'utils/Object';
import { KTreeNode } from 'types/api/Tenant/AROKMS/KTreeDataTypes';
import { useGetLastSyncHistory } from 'services/v1/Tenant/AROEvent/EventDataSourceService';
import { dayjs } from 'utils/DateUtils';
import { ENV_CONFIG } from 'config/env';

interface SettingPanelConfigProps {
  insertButtonText?: string;
  insertInitialObject?: { [key: string]: string | number | boolean };
  fieldFocusOnInsert: string;
  deleteButtonText?: string;
}

interface OptionItem {
  value: string;
  label: string;
}

interface KTreeOptionFilter {
  lowestMemberLevel: number;
  tableDefinitionId: string;
  options: KTreeNode[];
}

interface DataInputSettingPanelProps {
  onDeleteData?: () => void;
  onKTreeFilterChange?: (filter: { [key: string]: KTreeNode }) => void;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  setFilterButtonElement: () => void;
  config: SettingPanelConfigProps;
  isInserting: boolean;
  title: string;
  options: OptionItem[];
  initialOption?: OptionItem | undefined;
  viewOnly?: boolean;
  withChipLabel?: boolean;
  optionValue: OptionItem;
  disabled: boolean;
  connectedToDataSource: boolean;
  onOptionChange: (value: OptionItem) => void;
  onExport: (selectedFileType: FileType) => void;
  onImport: (selectedFileType: FileType) => void;
  onButtonTemplateClick: (selectedFileType: FileType) => void;
  disabledDeleteButton?: boolean;
  onAddDataClick?: () => void;
  onOptionClick?: (type: EventTableOptions) => void;
  onButtonTableAnalyticClick?: (data: FormTableAnalyticParameter) => void;
}

const buttonActionStyle = {
  padding: 1.4,
};

export function DataInputSettingPanel(props: DataInputSettingPanelProps) {
  const isMutating = Boolean(useIsMutating());
  const {
    setFilterButtonElement,
    setRows,
    setRowModesModel,
    config,
    disabled,
    viewOnly,
    onImport,
    onButtonTemplateClick,
    onAddDataClick = undefined,
    onButtonTableAnalyticClick,
    onOptionClick = () => {},
    onKTreeFilterChange,
    connectedToDataSource = false,
  } = props;

  const apiRef = useGridApiContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const myViewId = searchParams.get('myViewId');

  const { subjectId } = useParams<{ subjectId: string }>();
  const { selectedTenant, role } = useSelector((state: RootState) => state.auth);
  const { data: subjectDetails } = useGetSubjectDetails(subjectId);
  const { data: allMyViewsData } = useGetMyPersonalization({ enabled: true });
  const { data: myViewDetails } = useGetMyViewDetails(myViewId);
  const { data: tableSettingPanel } = useGetDisplayTableSettingPanel(subjectId);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<{ [key: string]: KTreeNode }>({});
  const { data: lastSyncEventData } = useGetLastSyncHistory(subjectId, 0);
  const prefetchHandler = useDashboardNaviagationPrefetchHandler();

  const memberOptions = useMemo(() => {
    const memberData = new Map<string, KTreeOptionFilter>();

    if (tableSettingPanel?.data) {
      tableSettingPanel.data.forEach((item) => {
        const memberDataList: KTreeNode[] = flattenTreeData(item.options);
        const lowestMemberLevel = memberDataList.reduce((acc, item) => {
          if (item.level > acc) {
            return item.level;
          } else {
            return acc;
          }
        }, 0);
        const kTreeFilter: KTreeOptionFilter = {
          lowestMemberLevel,
          options: memberDataList,
          tableDefinitionId: item.tableDefinitionId,
        };
        if (memberDataList.length > 0) {
          memberData.set(item.tableDefinitionId, kTreeFilter);
        } else {
          memberData.set(item.tableDefinitionId, {
            lowestMemberLevel: 0,
            options: [],
            tableDefinitionId: item.tableDefinitionId,
          });
        }
      });
    }
    return memberData;
  }, [tableSettingPanel?.data]);

  useEffect(() => {
    if (memberOptions.size > 0) {
      const selectedFilterOptions: { [key: string]: KTreeNode } = {};
      // Select the level 999
      memberOptions.forEach((value, key) => {
        const selectedOption = value.options.find((option) => option.level === 0);
        if (selectedOption) {
          selectedFilterOptions[value.tableDefinitionId] = selectedOption;
        }
      });
      setSelectedFilterOptions(selectedFilterOptions);
    }
  }, [memberOptions]);

  useEffect(() => {
    if (onKTreeFilterChange) {
      onKTreeFilterChange(selectedFilterOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOptions]);

  const { data: importActivity } = useGetCurrentImportActivity(subjectId);
  const isOnImportActivityProgress = useMemo(() => {
    return (
      importActivity?.data?.status === DataInputImportActivityStatus.ON_PROGRESS ||
      importActivity?.data?.status === DataInputImportActivityStatus.PENDING
    );
  }, [importActivity?.data?.status]);

  const { fieldFocusOnInsert = '', insertButtonText = 'Add', insertInitialObject = {} } = config;

  const insightViewList = useMemo(() => {
    if (allMyViewsData?.data) {
      const selectedDashboardId = myViewDetails?.data.dashboardId;
      const selectedDashboard =
        allMyViewsData.data.dashboards.find((dashboardItem) => dashboardItem.id === selectedDashboardId) || null;

      if (selectedDashboard) {
        return selectedDashboard.myViews;
      }
    }

    return [];
  }, [allMyViewsData?.data, myViewDetails?.data]);

  const currentMyViewIndex = useMemo(() => {
    if (myViewId == null) return 0;
    return getCurrentMyViewIndex(insightViewList, myViewId);
  }, [myViewId, insightViewList]);

  const nextViewItem = useMemo(() => {
    return getNextMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const previousViewItem = useMemo(() => {
    return getPreviousMyViewItem(insightViewList, currentMyViewIndex);
  }, [currentMyViewIndex, insightViewList]);

  const handleAddData = () => {
    if (onAddDataClick) {
      onAddDataClick();
    } else {
      const id = new Date().getTime();
      setRows((oldRows) => [{ id, ...insertInitialObject }, ...oldRows]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldFocusOnInsert },
      }));
    }
  };

  const handleExportCLick = (fileType: string) => {
    if (fileType === FILE_TYPE_PARAMS.EXCEL) {
      const exportConfig = {
        columnNames: generateColumnNames(apiRef),
        keys: generateColumnNames(apiRef),
        sheetName: 'Sheet1',
        fileName: `${subjectDetails?.data?.displayName}_${selectedTenant.tenant.tenantName}.xlsx`,
      };
      handleExport(apiRef, exportConfig);
    }
    if (fileType === FILE_TYPE_PARAMS.CSV) {
      apiRef.current.exportDataAsCsv({
        fileName: `${subjectDetails?.data?.displayName}_${selectedTenant.tenant.tenantName}.csv`,
      });
    }
  };

  const handleOnDashboardNavigate = (direction: ControllerNavigationDirection) => {
    switch (direction) {
      case ControllerNavigationDirection.LEFT:
        if (previousViewItem) {
          const previousURL = getQuickAccessNavigateURL(previousViewItem, undefined, PAGE_REF_NAVIGATION.LEFT, role);
          previousURL && navigate(previousURL);
        }
        break;
      case ControllerNavigationDirection.RIGHT:
        if (nextViewItem) {
          const nextURL = getQuickAccessNavigateURL(nextViewItem, undefined, PAGE_REF_NAVIGATION.RIGHT, role);
          nextURL && navigate(nextURL);
        }
        break;
      default:
        break;
    }
  };

  const handleOnDashboardNavigatePrefetch = (direction: ControllerNavigationDirection) => {
    switch (direction) {
      case ControllerNavigationDirection.LEFT:
        if (previousViewItem) {
          const afterPreviousItemIndex = currentMyViewIndex - 2;
          const afterPreviousItem = insightViewList?.[afterPreviousItemIndex] || undefined;
          prefetchHandler(previousViewItem, afterPreviousItem);
        }
        break;
      case ControllerNavigationDirection.RIGHT:
        if (nextViewItem) {
          const afterNextItemIndex = currentMyViewIndex + 2;
          const afterNextItem = insightViewList?.[afterNextItemIndex] || undefined;
          prefetchHandler(nextViewItem, afterNextItem);
        }
        break;
      default:
        break;
    }
  };

  const navigationControllerTooltips = useMemo(() => {
    const defaultToolTips: {
      [key: string]: string;
    } = {};
    if (insightViewList.length > 1) {
      if (currentMyViewIndex > -1) {
        if (currentMyViewIndex > 0) {
          defaultToolTips[ControllerNavigationDirection.LEFT] = `Show ${insightViewList[currentMyViewIndex - 1].title}`;
        }
        if (currentMyViewIndex < insightViewList.length - 1) {
          defaultToolTips[ControllerNavigationDirection.RIGHT] = `Show ${
            insightViewList[currentMyViewIndex + 1].title
          }`;
        }
      }
    }
    return defaultToolTips;
  }, [currentMyViewIndex, insightViewList]);

  const controllerNaviations = useMemo(() => {
    const controller = [];
    if (insightViewList?.length > 1) {
      if (currentMyViewIndex > 0) controller.push(ControllerNavigationDirection.LEFT);
      if (currentMyViewIndex < insightViewList.length - 1) controller.push(ControllerNavigationDirection.RIGHT);
    }
    return controller;
  }, [currentMyViewIndex, insightViewList]);

  const subjectMenuOptionsId = useMemo(() => {
    const baseOptions = [
      DataInputTableOptions.CUSTOM_RULES,
      DataInputTableOptions.IMPORT_DATA_HISTORY,
      DataInputTableOptions.FUNCTION_TRIGGER,
    ];
    if (connectedToDataSource) {
      return [...baseOptions, EventTableOptions.MODIFY_DATA_SOURCE_CONNECTION];
    } else {
      return [...baseOptions, EventTableOptions.CONNECT_DATA_SOURCE];
    }
  }, [connectedToDataSource]);

  return (
    <>
      <Stack direction='row' justifyContent='space-between' spacing={0} sx={{ backgroundColor: '#fff', py: 1.8 }}>
        <Stack direction='row' alignItems='center'>
          {insertButtonText && connectedToDataSource === false && (
            <RoleBasedAccessProvider
              allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
            >
              <SystemStatusBasedAccessProvider>
                {subjectDetails?.data?.type !== SUBJECT_TYPE.VIEW && !isOnImportActivityProgress && !viewOnly && (
                  <Typograhpy variant='subtitle1' sx={{ fontWeight: 600 }}>
                    <Button
                      disabled={disabled || isMutating}
                      variant='main-table-panel'
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      onClick={handleAddData}
                    >
                      {insertButtonText}
                    </Button>
                  </Typograhpy>
                )}
              </SystemStatusBasedAccessProvider>
            </RoleBasedAccessProvider>
          )}

          {connectedToDataSource && (
            <Stack direction='row' color='GrayText' alignItems='center' gap={1} justifyContent='center'>
              {lastSyncEventData?.data?.dataSourceConfig?.logoUrl && (
                <>
                  <img
                    src={lastSyncEventData?.data?.dataSourceConfig?.logoUrl}
                    alt='logo'
                    style={{
                      maxWidth: 90,
                      height: 'auto',
                    }}
                  />
                  <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
                </>
              )}

              <Alert variant='outlined' severity='info'>
                <Stack>
                  <Typograhpy variant='caption'>
                    This table's data is synced with the external source "
                    {lastSyncEventData?.data?.dataSourceConfig?.sourceTableName}"
                  </Typograhpy>
                  {lastSyncEventData?.data?.latestPullHistory?.lastPullDate && (
                    <Typograhpy variant='caption' fontSize={11} fontStyle='italic'>
                      Last updated on{' '}
                      {dayjs(lastSyncEventData?.data?.latestPullHistory?.lastPullDate).format(
                        ENV_CONFIG.config.DATE_TIME_FORMAT
                      )}
                    </Typograhpy>
                  )}
                </Stack>
              </Alert>
            </Stack>
          )}

          {isOnImportActivityProgress && (
            <Stack direction='row' color='GrayText' alignItems='center'>
              <SyncAltOutlinedIcon sx={{ mr: 0.5 }} fontSize='small' />
              <Typograhpy variant='body2'>{`
       Importing ${importActivity?.data.totalCurrentProcessedRecords ?? 0} of ${
                importActivity?.data.totalRecords ?? 0
              } records ...
   `}</Typograhpy>
            </Stack>
          )}
        </Stack>

        <Stack direction='row' spacing={0} alignContent='center' alignItems='center'>
          <GridToolbarFilterButton
            ref={setFilterButtonElement}
            componentsProps={{
              button: {
                disabled: disabled,
                sx: buttonActionStyle,
                variant: 'main-table-panel-borderless',
              },
            }}
          />
          <GridToolbarDensitySelector sx={buttonActionStyle} variant='main-table-panel-borderless' />
          <ButtonTableAnalytics onDisplayData={onButtonTableAnalyticClick} />
          <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5 }} />
          <ButtonExport disabled={disabled} onClick={handleExportCLick} />
          <RoleBasedAccessProvider
            allowedRoles={[ROLES.BUILDER, ROLES.ADMIN, ROLES.TESTER, ROLES.SUPPORT, ROLES.PACKAGER]}
          >
            <SystemStatusBasedAccessProvider>
              {subjectDetails?.data?.type !== SUBJECT_TYPE.VIEW && connectedToDataSource === false && (
                <>
                  <ButtonImport disabled={disabled || isOnImportActivityProgress} onClick={onImport} />
                  <ButtonDownloadTemplate
                    disabled={disabled || isOnImportActivityProgress}
                    onClick={onButtonTemplateClick}
                  />
                </>
              )}
              {subjectDetails?.data?.type !== SUBJECT_TYPE.VIEW && (
                <ButtonTableOptions onClick={onOptionClick} menuOptionsId={subjectMenuOptionsId} />
              )}
            </SystemStatusBasedAccessProvider>
          </RoleBasedAccessProvider>
          {myViewId && (
            <>
              <Divider orientation='vertical' variant='middle' flexItem sx={{ px: 0.5, mr: 2 }} />
              <ControllerNavigation
                onPrefetch={handleOnDashboardNavigatePrefetch}
                enableNavigations={controllerNaviations}
                tooltips={navigationControllerTooltips}
                onNavigate={handleOnDashboardNavigate}
              />
            </>
          )}
        </Stack>
      </Stack>
      {tableSettingPanel?.data && tableSettingPanel.data.length > 0 && (
        <Stack
          direction='row'
          alignItems='center'
          sx={{
            bgcolor: '#FBFBFB',
            py: 1,
            px: 2,
            mb: 1,
            gap: 2,
            border: '1px solid #E3EBF6',
          }}
        >
          {tableSettingPanel.data.map((item) => (
            <Autocomplete
              // @ts-ignore
              onChange={(event, value) => {
                if (!!value) {
                  setSelectedFilterOptions((prev) => ({
                    ...prev,
                    [item.tableDefinitionId]: value,
                  }));
                }
              }}
              clearIcon={null}
              sx={{
                minWidth: 280,
              }}
              size='small'
              // @ts-ignore
              value={{
                value: selectedFilterOptions[item.tableDefinitionId]?.value ?? '',
                label: selectedFilterOptions[item.tableDefinitionId]?.label ?? '',
                level: selectedFilterOptions[item.tableDefinitionId]?.level ?? 0,
              }}
              isOptionEqualToValue={(option: AutoCompleteItem, value: AutoCompleteItem) => option.value === value.value}
              disabled={disabled}
              getOptionLabel={(option: AutoCompleteItem) => option.label}
              options={memberOptions.get(item.tableDefinitionId)?.options ?? []}
              renderOption={(props, option, state) =>
                renderOptionsKtree(props, option, state, memberOptions.get(item.tableDefinitionId)?.lowestMemberLevel)
              }
              ListboxProps={{
                ...getListBoxPropsAutoScrolItem(
                  memberOptions.get(item.tableDefinitionId)?.options ?? [],
                  memberOptions.get(item.tableDefinitionId)?.lowestMemberLevel
                ),
              }}
              renderInput={(params) => (
                <Stack direction='row' justifyContent='space-between' display='flex' alignItems='center'>
                  <TextField {...params} label={item.ktreeName} name='member' />
                </Stack>
              )}
            />
          ))}
        </Stack>
      )}
    </>
  );
}
